import React from 'react';
import { Button } from '@/components/ui/button';
import {
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import useMarkPromptTemplate from '@/actions/api/useMarkPromptTemplate';
import { toast } from 'sonner';

type Props = {
  promptName: string;
  setActiveDialogOpen: React.Dispatch<number>;
  refetchPrompts: () => void;
};

const ActiveDialog = ({
  promptName,
  setActiveDialogOpen,
  refetchPrompts
}: Props) => {
  const archivePromptTemplate = useMarkPromptTemplate(promptName);

  const handlePromptActive = async () => {
    archivePromptTemplate
      .mutateAsync({ status: 'active' })
      .then(() => {
        toast.success('Prompt template marked active successfully!');
        setActiveDialogOpen(-1);
        refetchPrompts();
      })
      .catch(e => {
        console.log(e);
        toast.error('Oops! Something went wrong. Please try again');
      });
  };

  return (
    <div className='flex flex-col gap-5'>
      <DialogHeader>
        <DialogTitle>Are you sure to mark this prompt as Active?</DialogTitle>
      </DialogHeader>

      <DialogFooter>
        <Button variant='outline' onClick={() => setActiveDialogOpen(-1)}>
          Cancel
        </Button>
        <Button onClick={handlePromptActive}>Active</Button>
      </DialogFooter>
    </div>
  );
};

export default ActiveDialog;

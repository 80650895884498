import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "../ui/button";
import { llmFeedbacksContext, llmRatingParametersContext } from "@/pages/logs";
import { Slider } from "@/components/ui/slider";
import { toast } from "sonner";
import usePostLLMFeedback from "@/actions/api/usePostLLMFeedback";
import moment from "moment";
import { Input } from "../ui/input";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import { cn } from "@/lib/utils";

type RatingParameter = {
  name: string;
  regex_condition: string;
  min_rating: number;
  max_rating: number;
};
type Rating = {
  param_name: string;
  rating: number | null;
};

const CompletionText = ({
  text,
  llmEvent,
  sentryTraceId = "",
}: {
  text: string;
  llmEvent?: any;
  sentryTraceId?: string;
}) => {
  const feedbacks = useContext(llmFeedbacksContext);
  const llmRatingParameters = useContext(llmRatingParametersContext);

  const [ratings, setRatings] = useState<Rating[]>([]);
  const postLLMFeedback = usePostLLMFeedback();
  const [additionalInfo, setAdditionalInfo] = useState("");

  const ratingParameters = useMemo(() => {
    const parameters: RatingParameter[] =
      llmRatingParameters[llmEvent["associated_event_name"]] || [];

    const finalParameters = parameters?.filter((parameter) => {
      if (parameter.regex_condition.length === 0) return true;
      const regex = new RegExp(parameter.regex_condition);
      return regex.test(text);
    });

    const dummy_ratings: Rating[] = [];
    finalParameters.forEach((parameter) => {
      dummy_ratings.push({
        param_name: parameter.name,
        rating: null,
      });
    });
    setRatings(dummy_ratings);

    return finalParameters;
  }, [llmRatingParameters, text, llmEvent]);

  const handleRatingChange = (parameterName: string, rating: number) => {
    setRatings((curr) =>
      curr.map((rate) =>
        rate.param_name === parameterName
          ? { ...rate, rating: rating === rate.rating ? null : rating }
          : rate
      )
    );
  };

  const handleRatingSubmit = async () => {
    await postLLMFeedback
      .mutateAsync({
        timestamp: llmEvent["timestamp"],
        sentry_trace_id: sentryTraceId,
        event_name: llmEvent["associated_event_name"],
        additional_info: additionalInfo,
        feedbacks: ratings,
      })
      .then((res) => {
        if (!!res.detail) throw new Error();
        toast.success("Your feedback has been submitted successfully!");
      })
      .catch(() => {
        toast.error("Oops! Something went wrong. Please try again");
      });
  };

  useEffect(() => {
    const existingFeedback = feedbacks.find(
      (feedback: any) =>
        feedback.sentry_trace_id === sentryTraceId &&
        moment(feedback.timestamp).isSame(llmEvent["timestamp"])
    );

    if (existingFeedback) {
      setRatings(existingFeedback.feedbacks);
      setAdditionalInfo(existingFeedback.additional_info);
    }
  }, [feedbacks, sentryTraceId, llmEvent]);

  return (
    <div>
      <p className="whitespace-pre-line">{text}</p>
      <div className="flex flex-col gap-3 items-center mt-3 mb-4">
        <p className="font-semibold self-start">Rating Parameters</p>
        {ratingParameters.map((parameter, ind) => (
          <div
            key={ind}
            className="flex justify-between w-full gap-5 items-center"
          >
            <p className="capitalize">{parameter.name}</p>
            {parameter.max_rating - parameter.min_rating !== 1 ? (
              <Slider
                max={parameter.max_rating}
                step={1}
                min={parameter.min_rating}
                className="max-w-[200px] h-7"
                onValueChange={(val) =>
                  handleRatingChange(parameter.name, val[0])
                }
                value={[
                  ratings.find((rating) => rating.param_name === parameter.name)
                    ?.rating || 0,
                ]}
              />
            ) : (
              <div className="flex gap-5 items-center h-7">
                <ThumbsDown
                  className={cn(
                    "self-end cursor-pointer",
                    ratings.find(
                      (rating) => rating.param_name === parameter.name
                    )?.rating === parameter.min_rating && "fill-slate-400"
                  )}
                  onClick={() =>
                    handleRatingChange(parameter.name, parameter.min_rating)
                  }
                />
                <ThumbsUp
                  className={cn(
                    "self-start cursor-pointer",
                    ratings.find(
                      (rating) => rating.param_name === parameter.name
                    )?.rating === parameter.max_rating && "fill-slate-400"
                  )}
                  onClick={() =>
                    handleRatingChange(parameter.name, parameter.max_rating)
                  }
                />
              </div>
            )}
          </div>
        ))}
        <Input
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          placeholder="Any additional comment ?"
        />
      </div>
      <Button className="" onClick={handleRatingSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default CompletionText;

import React, { useEffect, useMemo, useState } from "react";
import { LogEvent } from "@/types";
import { Outlet, useLocation } from "react-router-dom";
import Filters from "@/components/logs-filters";
import ParticularLogPage from "./particular-log";
import CustomPagination from "@/components/pagination";
import useLLMRatingParameters from "@/actions/api/useLLMRatingParameters";
import Loader from "@/components/loader";
import useFetchLLMFeedback, {
  Filter,
} from "@/actions/api/useFetchLLMFeedbacks";
import { toast } from "sonner";

export const llmRatingParametersContext = React.createContext<any>([]); // For storing parameters
export const llmFeedbacksContext = React.createContext<any>([]); // For existing feedbacks

const LogsPage = () => {
  const ITEM_IN_ONE_PAGE = 10;
  const { pathname } = useLocation();

  const [filteredLogs, setFilteredLogs] = useState<LogEvent>([]);
  const [totalLogsCount, setTotalLogsCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [compactView, setCompactView] = useState(true);

  const [expandedEvent, setExpandedEvent] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);

  const [feedbacks, setFeedbacks] = useState([]);

  const fetchLLMFeedback = useFetchLLMFeedback();
  const { data: llmRatingParameters = [] } = useLLMRatingParameters();

  const totalPages = useMemo(
    () => Math.ceil(totalLogsCount / ITEM_IN_ONE_PAGE),
    [totalLogsCount]
  );

  const filterForFeedback = useMemo(() => {
    const filters: Filter[] = [];
    filteredLogs.forEach((log: LogEvent) => {
      const subFilters = log.llm_component_events.map((llm: any) => ({
        timestamp: llm.llm_event.timestamp,
        sentry_trace_id: llm.event.sentry_trace_id,
      }));
      filters.push(...subFilters);
    });
    return filters;
  }, [filteredLogs]);

  useEffect(() => {
    if (!filterForFeedback.length) {
      setFeedbacks([]);
    } else {
      fetchLLMFeedback
        .mutateAsync(filterForFeedback)
        .then((res) => {
          setFeedbacks(res?.feedbacks || []);
          toast.success("Fetched feedbacks");
        })
        .catch(() => {
          toast.error("Something went wrong!!");
        });
    }

    // eslint-disable-next-line
  }, [filterForFeedback]);

  return (
    <llmRatingParametersContext.Provider value={llmRatingParameters}>
      <llmFeedbacksContext.Provider value={feedbacks}>
        <div className="p-5 min-h-dvh flex flex-col">
          {pathname === "/logs" ? (
            <div className="flex flex-col gap-10 grow">
              <Filters
                setFilteredLogs={setFilteredLogs}
                currentPage={currentPage}
                setLoading={setLoading}
                setTotalLogsCount={setTotalLogsCount}
                setCompactView={setCompactView}
                compactView={compactView}
              />

              <div className="grow flex flex-col">
                <div className="flex justify-between px-4 mb-2">
                  <p className="w-2/5">Event Name</p>
                  <p className="w-1/5">Org Id</p>
                  <p className="w-1/5">User id</p>
                </div>
                <div className="grow flex flex-col gap-2 relative">
                  {loading && <Loader className="w-full h-full absolute" />}
                  {filteredLogs?.map((event: LogEvent, ind: number) => (
                    <ParticularLogPage
                      event={event}
                      key={ind}
                      expandedEvent={ind === expandedEvent}
                      setExpandedEvent={() =>
                        setExpandedEvent((curr) => (curr === ind ? -1 : ind))
                      }
                      compactView={compactView}
                    />
                  ))}

                  {filteredLogs?.length === 0 && (
                    <div className="flex grow items-center justify-center">
                      <p className="">No logs to list</p>
                    </div>
                  )}
                </div>
              </div>
              <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChangePage={setCurrentPage}
              />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </llmFeedbacksContext.Provider>
    </llmRatingParametersContext.Provider>
  );
};

export default LogsPage;

import { CURRENT_ENVIRONMENT } from "./constants";
import { Environvent } from "./types";

const config = {
  production: {
    backend_url: "https://api.metaforms.ai/www",
    app_web_url: "https://internal.metaforms.ai",
    web_url: "https://internal.metaforms.ai",
    production_backend_url: "https://api.metaforms.ai/www",
    qa_backend_url: "https://api.qa.metaforms.ai/www",
  },
  development: {
    backend_url: "https://api.dev.metaforms.ai/www",
    production_backend_url: "https://api.metaforms.ai/www",
    app_web_url: "https://internal.metaforms.ai",
    web_url: "https://internal.metaforms.ai",
    qa_backend_url: "https://api.qa.metaforms.ai/www",
  },
  qa: {
    backend_url: "https://api.qa.metaforms.ai/www",
    app_web_url: "https://internal.metaforms.ai",
    production_backend_url: "https://api.metaforms.ai/www",
    web_url: "https://internal.metaforms.ai",
    qa_backend_url: "https://api.qa.metaforms.ai/www",
  },
  local: {
    backend_url: "http://localhost:12000/www",
    app_web_url: "http://localhost:3000",
    production_backend_url: "https://api.metaforms.ai/www",
    web_url: "http://localhost:3000",
    qa_backend_url: "https://api.qa.metaforms.ai/www",
  },
};

let environment = (localStorage.getItem(CURRENT_ENVIRONMENT) ??
  "development") as Environvent;

export default config[environment];

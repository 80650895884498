import { QueryFunction, useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

const useSolar = (queryString: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchRatingParameters: QueryFunction<
    any,
    string[],
    never
  > = async () => {
    const url = `${config.backend_url}/solr-interface/form_views`;

    // Append the query string to the URL
    const urlWithParams = `${url}?${queryString}&org_id=workhack`;

    const res = await fetchWithAuth(urlWithParams).then((res) => res.json());

    if (res?.status === "error") {
      throw new Error(res?.description);
    }

    return res;
  };

  return useQuery({
    queryKey: ["usesolar", queryString],
    queryFn: fetchRatingParameters,
  });
};

export default useSolar;

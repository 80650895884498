import React from "react";
import { RouterProvider } from "react-router-dom";

import { Toaster } from "@/components/ui/sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./router";
import { SessionProvider } from "./actions/useSession";

const queryClient = new QueryClient();

const App = () => {
  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </SessionProvider>
  );
};

export default App;

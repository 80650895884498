import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { SquarePlus } from 'lucide-react';
import React, { useState } from 'react';
import NewLlmConfigDialog from './new-llm-config-dialog';

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible';
import { Separator } from '@/components/ui/separator';

export type AttributeType = {
  name: string;
  min: number;
  max: number;
};

const LLmRatingConfigPage = () => {
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [ratingConfigs, setRatingConfigs] = useState<any[]>([]);

  const handleSubmitNewLlmConfig = (eventName: string) => {
    setRatingConfigs(curr => [
      ...curr,
      {
        event_name: eventName,
        attributes: []
      }
    ]);
    setNewDialogOpen(false);
  };

  return (
    <div className='p-5 flex min-h-dvh flex-col'>
      <div className='flex justify-between items-center'>
        <h1 className='text-3xl font-semibold'>LLM Rating Attributes Config</h1>
        <Dialog open={newDialogOpen} onOpenChange={setNewDialogOpen}>
          <DialogTrigger>
            <Button>
              <SquarePlus className='mr-2' /> New Prompt
            </Button>
          </DialogTrigger>
          <DialogContent>
            <NewLlmConfigDialog
              handleSubmitNewLlmConfig={handleSubmitNewLlmConfig}
            />
          </DialogContent>
        </Dialog>
      </div>

      <div className='my-14'>
        {ratingConfigs.map((ratingConfig: any, ind: number) => (
          <Collapsible key={ind}>
            <CollapsibleTrigger asChild>
              <div className='p-4 cursor-pointer'>
                <p className='font-semibold text-xl'>
                  {ratingConfig.event_name}
                </p>
              </div>
            </CollapsibleTrigger>
            {!!ratingConfig?.attributes?.length && (
              <CollapsibleContent className='p-5 pt-1'>
                <p className='font-medium text-lg'>Attributes</p>
                {ratingConfig?.attributes?.map((attribute: AttributeType) => (
                  <div>
                    <p>{attribute.name}</p>
                    <div>
                      <p>{attribute.min}</p>
                      <p>{attribute.max}</p>
                    </div>
                  </div>
                ))}
              </CollapsibleContent>
            )}
            <Separator />
          </Collapsible>
        ))}
      </div>
    </div>
  );
};

export default LLmRatingConfigPage;

import React, { useEffect, useState } from 'react';

import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { Calendar as CalendarIcon } from 'lucide-react';
import moment from 'moment';
import { cn } from '@/lib/utils';
import { Input } from '../ui/input';

import './styles.css';

const DateTimePicker = ({
  datetime,
  setDatetime
}: {
  datetime?: string;
  setDatetime: any;
}) => {
  const [date, setDate] = useState<number>(Date.now());
  const [time, setTime] = useState<{
    hour: number;
    minute: number;
  }>({
    hour: 0,
    minute: 0
  });

  const setHour = (value: number) => {
    if (!value || (value >= 0 && value <= 23)) {
      setTime(curr => ({ ...curr, hour: value || 0 }));
    }
  };

  const setMinute = (value: number) => {
    if (!value || (value >= 0 && value <= 59)) {
      setTime(curr => ({ ...curr, minute: value || 0 }));
    }
  };

  useEffect(() => {
    if (datetime) {
      setDate(moment(datetime).date());
      setTime({
        hour: moment(datetime).hours(),
        minute: moment(datetime).minutes()
      });
    }
  }, [datetime]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'justify-start text-left font-normal w-full',
            !date && 'text-muted-foreground'
          )}
        >
          <CalendarIcon className='mr-2 h-4 w-4' />
          {date ? (
            `${moment(date).format('DD-MM-YYYY')} - ${time.hour}:${time.minute}`
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0'>
        <Calendar
          mode='single'
          selected={new Date(date)}
          onSelect={date => setDate(moment(date).unix() * 1000)}
          initialFocus
        />
        <div className='border-t h-12 p-1 flex items-center gap-1 justify-center'>
          <Input
            className='h-full w-10 border rounded p-2 flex justify-center items-center hide-input-arrows text-center'
            value={time.hour}
            onChange={e => setHour(parseInt(e.target.value))}
            type='number'
            min={0}
            max={23}
          />
          <span>:</span>
          <Input
            className='h-full w-10 border rounded p-2 flex justify-center items-center hide-input-arrows text-center'
            value={time.minute}
            onChange={e => setMinute(parseInt(e.target.value))}
            type='number'
            min={0}
            max={59}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default DateTimePicker;

import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { toast } from "sonner";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import SavePromptDialog from "./save-prompt-dialog";
import { cn } from "@/lib/utils";
import AutoResizeTextarea from "@/components/auto-resize-textarea";
import { Prompt } from "../datasets/particular-dataset";
import useCurrentEnvironment from "@/actions/useCurrentEnvironment";

export type PromptType = {
  role?: string;
  content: string;
};

type Props = {
  handleSavePromptAsLatest: ({
    commitMessage,
    chats,
    forcePushTo,
    callback,
  }: {
    commitMessage: string;
    chats: Prompt["messages"];
    forcePushTo?: "qa" | "prod";
    callback?: () => void;
  }) => Promise<void>;
  handleSaveAsDraft?: ({
    commitMessage,
    draftName,
    chats,
    callback,
  }: {
    commitMessage: string;
    draftName: string;
    chats: Prompt["messages"];
    callback?: () => void;
  }) => Promise<void>;
  prompt?: Prompt["messages"];
  showRunBtn?: boolean;
  onChangeChats?: (chats: any[], ind: number) => void;
  activeInd?: number;
  handleSaveToLocal?: (chats: Prompt["messages"], index: number) => void;
  showSaveBtn?: boolean;
  isDataset?: boolean;
};

const PromptConfig = ({
  prompt = [],
  showRunBtn = true,
  handleSaveAsDraft,
  handleSavePromptAsLatest,
  onChangeChats,
  activeInd = 1,
  handleSaveToLocal,
  showSaveBtn = true,
  isDataset = false,
}: Props) => {
  const currentEnvironment = useCurrentEnvironment();
  const promptContainerRef = useRef<HTMLDivElement>(null);
  const roles = ["user", "system", "assistant"];
  const [error, setError] = useState("");
  const [chats, setChats] = useState<
    {
      role: string;
      content: string;
    }[]
  >(() =>
    !prompt.length
      ? [
          {
            role: "system",
            content: "",
          },
        ]
      : prompt
  );

  const [saveDialogData, setSaveDialogData] = useState<{
    open: boolean;
    forcePushTo?: "qa" | "prod";
  }>({
    open: false,
    forcePushTo: undefined,
  });

  const chatsRef = useRef<any>([]);
  const prevActiveInd = useRef<number>(-1);

  const checkForErrors = (messages: typeof chats) => {
    let prevRole: string = "";
    for (let message of messages) {
      if (!message.role) {
        setError(`Role must be defined!!`);
        break;
      }

      // Consecutive user/assistant role items found
      if (message.role === prevRole) {
        setError(`Consecutive ${prevRole} role found!!`);
        return;
      }
      prevRole = message.role as string;
    }

    // Last item not user
    if (!isDataset && prevRole !== "user") {
      setError("Last message must be from user!!");
    }
  };

  const handleRun = () => {
    checkForErrors(chats);
  };

  const handleAddChat = () => {
    setChats((chats: Prompt["messages"]) => [
      ...chats,
      {
        role:
          chats[chats.length - 1].role === "assistant" ? "user" : "assistant",
        content: "",
      },
    ]);
    setTimeout(() => {
      if (promptContainerRef.current) {
        promptContainerRef.current.lastElementChild?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 0);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(chats));
    toast.success("Copied prompt to clipboard");
  };

  const handlePasteFromClipboard = async () => {
    const clipboardText = await navigator.clipboard.readText();
    try {
      const parsedClipboardText = JSON.parse(clipboardText);
      if (Array.isArray(parsedClipboardText)) {
        setChats(parsedClipboardText);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        "Unable to parse prompt from clipboard. Please make sure it is a valid JSON array"
      );
    }
    toast.success("Pasted prompt from clipboard");
  };

  const migrateToProductionClick = () => {
    setSaveDialogData({
      open: true,
      forcePushTo: "prod",
    });
  };
  const migrateToQAClick = () => {
    setSaveDialogData({
      open: true,
      forcePushTo: "qa",
    });
  };

  useEffect(() => {
    if (!!error) {
      toast.error(error);
      setError("");
    }
  }, [error]);

  useEffect(() => {
    chatsRef.current = chats;
    handleSaveToLocal?.(chats, activeInd);
    // eslint-disable-next-line
  }, [chats]);

  useEffect(() => {
    onChangeChats?.(chatsRef.current, prevActiveInd.current);
    prevActiveInd.current = activeInd;
    // eslint-disable-next-line
  }, [activeInd]);

  return (
    <div className="h-full flex flex-col gap-4 ">
      <div className="flex gap-5 justify-end">
        <Button variant="outline" onClick={handleCopyToClipboard}>
          Copy to Clipboard
        </Button>
        {!["production", "qa"].includes(currentEnvironment) && (
          <Button variant="outline" onClick={handlePasteFromClipboard}>
            Paste from Clipboard
          </Button>
        )}
        {["local", "development"].includes(currentEnvironment) && (
          <Button onClick={migrateToQAClick}>Migrate to QA</Button>
        )}
        {currentEnvironment === "qa" && (
          <Button onClick={migrateToProductionClick}>
            Migrate to Production
          </Button>
        )}
      </div>
      <div
        className="grow flex flex-col gap-4 overflow-y-scroll"
        ref={promptContainerRef}
      >
        {chats.map((chat, ind) => (
          <div key={ind} className="flex gap-4 relative p-2">
            <div
              className="absolute right-0 top-0 cursor-pointer"
              onClick={() =>
                setChats((chats) => chats.filter((_, i) => i !== ind))
              }
            >
              <Trash2 className="w-4 text-red-300" />
            </div>
            <div>
              <Label className="font-light text-sm text-gray-500">Role</Label>
              <Select
                value={chat.role}
                onValueChange={(value) =>
                  setChats((chats) =>
                    chats.map((chat, i) =>
                      ind === i ? { ...chat, role: value } : chat
                    )
                  )
                }
                disabled={["production", "qa"].includes(currentEnvironment)}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Role" />
                </SelectTrigger>
                <SelectContent>
                  {roles.map((role) => (
                    <SelectItem value={role} key={role} className="capitalize">
                      {role}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grow">
              <Label className="font-light text-sm text-gray-500">
                Message
              </Label>
              <AutoResizeTextarea
                value={chat.content}
                onChange={(e: any) =>
                  setChats((chats) =>
                    chats.map((chat, i) =>
                      i === ind ? { ...chat, content: e.target.value } : chat
                    )
                  )
                }
                disabled={["production", "qa"].includes(currentEnvironment)}
                placeholder="Type in the prompt..."
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-2">
        <Button
          className="min-w-[150px]"
          onClick={handleAddChat}
          disabled={["production", "qa"].includes(currentEnvironment)}
        >
          Add Chat
        </Button>

        {showRunBtn && (
          <Button className="min-w-[150px]" onClick={handleRun}>
            Run
          </Button>
        )}

        {/* Save Dialog */}
        <Dialog
          open={saveDialogData.open}
          onOpenChange={(isOpen) =>
            setSaveDialogData({ open: isOpen, forcePushTo: undefined })
          }
        >
          <DialogTrigger asChild>
            <Button
              className={cn(
                "min-w-[150px]",
                !showSaveBtn && "hidden",
                ["production", "qa"].includes(currentEnvironment) && "hidden"
              )}
            >
              Save
            </Button>
          </DialogTrigger>
          <DialogContent>
            <SavePromptDialog
              handleSavePromptAsLatest={({ commitMessage }) =>
                handleSavePromptAsLatest({
                  commitMessage,
                  chats,
                  callback() {
                    setSaveDialogData({ open: false, forcePushTo: undefined });
                  },
                  forcePushTo: saveDialogData.forcePushTo,
                })
              }
              handleSaveAsDraft={
                handleSaveAsDraft
                  ? ({ commitMessage, draftName }) =>
                      handleSaveAsDraft({
                        commitMessage,
                        chats,
                        draftName,
                        callback() {
                          setSaveDialogData({
                            open: false,
                            forcePushTo: undefined,
                          });
                        },
                      })
                  : undefined
              }
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default PromptConfig;

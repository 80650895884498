import React from 'react';
import { Button } from '@/components/ui/button';
import {
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';

const DeleteDialog = () => {
  return (
    <div className='flex flex-col gap-5'>
      <DialogHeader>
        <DialogTitle>Are you sure to delete this prompt?</DialogTitle>
      </DialogHeader>

      <DialogFooter>
        <Button variant='outline'>Cancel</Button>
        <Button>Delete</Button>
      </DialogFooter>
    </div>
  );
};

export default DeleteDialog;

//@ts-nocheck
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Pencil,
  SquarePlus,
  History,
  Archive,
  ArchiveRestore,
} from "lucide-react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { useNavigate } from "react-router-dom";

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import NewPromptDialog from "@/components/new-prompt-dialog";
import RenameDialog from "./rename-dialog";
import RestoreDialog from "./restore-dialog";
import ArchiveDialog from "./archive-dialog";
import Loader from "@/components/loader";
import moment from "moment";
import usePrompts from "@/actions/api/usePrompts";
import ActiveDialog from "./active-dialog";
import config from "@/config";

const PromptsListPage = () => {
  const navigate = useNavigate();

  const [newPromptDialogOpen, setNewPromptDialogOpen] = useState(false);
  const [promptStatus, setPromptStatus] = useState<"active" | "archive">(
    "active"
  );

  const prompts = usePrompts(promptStatus);
  const { data: promptList = [], isLoading } = prompts;

  // These will have the id of the prompt on which action is triggered
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(-1);
  const [renameDialogOpen, setRenameDialogOpen] = useState(-1);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(-1);
  const [activeDialogOpen, setActiveDialogOpen] = useState(-1);

  const handleNewPrompt = () => {
    setNewPromptDialogOpen(false);
  };

  const refetchPrompts = () => {
    prompts.refetch();
  };

  const handlePromptClick = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    promptName: string
  ) => {
    if (e.metaKey) {
      window.open(config.web_url + `/playground/${promptName}`, "_blank");
    } else {
      navigate(`/playground/${promptName}`);
    }
  };

  return (
    <div className="p-5 flex min-h-dvh flex-col">
      {!!isLoading && <Loader />}
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold">Prompts List</h1>
        <Dialog
          open={newPromptDialogOpen}
          onOpenChange={setNewPromptDialogOpen}
        >
          <DialogTrigger>
            <Button>
              <SquarePlus className="mr-2" /> New Prompt
            </Button>
          </DialogTrigger>
          <DialogContent>
            <NewPromptDialog onClick={handleNewPrompt} />
          </DialogContent>
        </Dialog>
      </div>

      <div className="mt-10 text-center">
        <Tabs
          value={promptStatus}
          onValueChange={(val) => setPromptStatus(val as "active" | "archive")}
        >
          <TabsList>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="archive">Archive</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      <div className="mt-10">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[150px]">Name</TableHead>
              <TableHead className="text-right">Last used</TableHead>
              <TableHead className="text-right">Last updated</TableHead>
              <TableHead className="text-right">Last updated by</TableHead>
              <TableHead className="text-right">
                Current version number
              </TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {promptList.map((prompt: any, ind: number) => (
              <TableRow key={ind}>
                <TableCell
                  className="font-medium cursor-pointer"
                  onClick={(e) => handlePromptClick(e, prompt.name)}
                >
                  {prompt.name}
                </TableCell>
                <TableCell className="text-right">
                  {prompt.last_used ? prompt.last_used : "-"}
                </TableCell>
                <TableCell className="text-right">
                  {prompt.last_updated
                    ? moment(prompt.last_updated).format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </TableCell>
                <TableCell className="text-right">
                  {prompt.last_updated_by || "-"}
                </TableCell>
                <TableCell className="text-right">
                  {prompt.curr_version_number}
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex gap-2 justify-end">
                    {/* Rename action */}
                    <Dialog
                      open={renameDialogOpen === ind}
                      onOpenChange={() =>
                        setRenameDialogOpen((curr) => (curr !== -1 ? -1 : ind))
                      }
                    >
                      <DialogTrigger>
                        <span title="Edit">
                          <Pencil className="cursor-pointer" />
                        </span>
                      </DialogTrigger>
                      <DialogContent>
                        <RenameDialog
                          promptName={prompt.name}
                          setRenameDialogOpen={setRenameDialogOpen}
                          refetchPrompts={refetchPrompts}
                        />
                      </DialogContent>
                    </Dialog>

                    {/* Restore action */}
                    {promptStatus === "active" && (
                      <Dialog
                        open={restoreDialogOpen === ind}
                        onOpenChange={() =>
                          setRestoreDialogOpen((curr) =>
                            curr !== -1 ? -1 : ind
                          )
                        }
                      >
                        <DialogTrigger>
                          <span title="Restore">
                            <History className="cursor-pointer" />
                          </span>
                        </DialogTrigger>
                        <DialogContent className="max-w-2xl ">
                          <RestoreDialog
                            promptName={prompt.name}
                            refetchPrompts={refetchPrompts}
                            setRestoreDialogOpen={setRestoreDialogOpen}
                          />
                        </DialogContent>
                      </Dialog>
                    )}

                    {/* Archive action */}
                    {promptStatus === "active" && (
                      <Dialog
                        open={archiveDialogOpen === ind}
                        onOpenChange={() =>
                          setArchiveDialogOpen((curr) =>
                            curr !== -1 ? -1 : ind
                          )
                        }
                      >
                        <DialogTrigger>
                          <span title="Archive">
                            <Archive className="cursor-pointer" />
                          </span>
                        </DialogTrigger>
                        <DialogContent>
                          <ArchiveDialog
                            promptName={prompt.name}
                            setArchiveDialogOpen={setArchiveDialogOpen}
                            refetchPrompts={refetchPrompts}
                          />
                        </DialogContent>
                      </Dialog>
                    )}

                    {/* Active action */}
                    {promptStatus === "archive" && (
                      <Dialog
                        open={activeDialogOpen === ind}
                        onOpenChange={() =>
                          setActiveDialogOpen((curr) =>
                            curr !== -1 ? -1 : ind
                          )
                        }
                      >
                        <DialogTrigger>
                          <span title="MArk as active">
                            <ArchiveRestore className="cursor-pointer" />
                          </span>
                        </DialogTrigger>
                        <DialogContent>
                          <ActiveDialog
                            promptName={prompt.name}
                            setActiveDialogOpen={setActiveDialogOpen}
                            refetchPrompts={refetchPrompts}
                          />
                        </DialogContent>
                      </Dialog>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PromptsListPage;

import { cn } from '@/lib/utils';
import { LogEvent } from '@/types';
import React, { ForwardedRef } from 'react';
import { useNavigate } from 'react-router-dom';

const HightLevelEventBox = React.forwardRef(
  (
    {
      event = {},
      className = ''
    }: {
      event: LogEvent;
      className?: string;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const navigate = useNavigate();
    return (
      <div
        ref={ref}
        className={cn(
          'border rounded p-4 flex justify-between cursor-pointer',
          className
        )}
        onClick={() => navigate(`${event.sentry_trace_id}`)}
      >
        <p className='w-2/5'>{event.event_name}</p>
        <p className='w-1/5'>{event.org_id}</p>
        <p className='w-1/5'>{event.user_id}</p>
        {/* <p className='w-1/5 text-right'>{event.timestamp}</p> */}
      </div>
    );
  }
);

export default HightLevelEventBox;

import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

const usePermissionManagerAdmin = (isadmin: boolean) => {
  const fetchWithAuth = useFetchWithAuth();
  const isadminlink = isadmin ? "admin" : "";
  const fetchEvents = async () => {
    const url = `${config.backend_url}/permission-manager/${isadminlink}`;
    const res = await fetchWithAuth(url).then((res) => res.json());

    if (res?.status === "error") {
      throw new Error(res?.description);
    }

    return res;
  };

  return useQuery({
    queryKey: ["permission-manager"],
    queryFn: fetchEvents,
  });
};

export default usePermissionManagerAdmin;

import React from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import config from "@/config";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  userid: z.string().min(1, "Username is required"),
});

type FormData = z.infer<typeof schema>;

function UserAccess() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const navigationUrl = `${config?.app_web_url}?userid=${data.userid}`;
    window.location.href = navigationUrl;
  };

  return (
    <div className="flex justify-center items-center h-screen w-full">
      <Card style={{ width: "500px" }}>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full p-4">
          <Input {...register("userid")} placeholder="Username" />
          {errors.userid && (
            <span className="text-red-500">{errors.userid.message}</span>
          )}
          <Button type="submit" className="mt-4">
            Redirect
          </Button>
        </form>
      </Card>
    </div>
  );
}

export default UserAccess;

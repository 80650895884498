import { useCallback } from "react";
import { encodeEmail } from "@/lib/utils";
import useSession from "./useSession";
import { supabase } from "@/supabaseClient";
import { useNavigate } from "react-router-dom";

const useFetchWithAuth = () => {
  const session = useSession();
  const navigate = useNavigate();

  const fetchWithAuth = useCallback(
    async (url: string, payload?: any) => {
      if (session === "loading") {
        throw new Error("Session loading");
      }
      if (typeof session?.access_token !== "string") {
        await supabase.auth.signOut();
        navigate("/", { replace: true });
      }

      const appId = encodeEmail(session.user.email);
      if (!payload) {
        payload = {};
      }
      if (!payload.headers) {
        payload.headers = {};
      }
      if (!payload.method) {
        payload.method = "GET"; // Set a default HTTP method if not provided
      }
      console.log("Making request with at", session.access_token);
      payload.headers["Authorization"] = "Bearer " + session.access_token;
      payload.headers["ngrok-skip-browser-warning"] = "asdf";
      payload.headers["App-Id"] = appId;
      payload.headers["User-Email"] = session.user.email;

      return await fetch(url, payload);
    },
    [session, navigate]
  );

  return fetchWithAuth;
};

export default useFetchWithAuth;

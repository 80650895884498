import useSolar from "@/actions/api/usesolar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import JSONPretty from "react-json-pretty";
import { z } from "zod";

const formSchema = z.object({
  q: z.string().optional(),
  rawquery: z.string().optional(),
  jsonquery: z.string().optional(),
  qvalue: z.enum(["OR", "AND"]).optional(),
  fq: z.string().optional(),
  sort: z.enum(["asc", "desc"]).optional(),
  start: z.string().optional(),
  rows: z.string().optional(),
  fl: z.string().optional(),
  df: z.string().optional(),
  wt: z.enum(["json", "xml", "python", "ruby", "php", "csv"]).optional(),
  indent: z.boolean().optional(),
  debugQuery: z.boolean().optional(),
  defType: z.enum(["dismax", "lucene", "edismax"]).optional(),
  hl: z.boolean().optional(),
  facet: z.boolean().optional(),
  spatial: z.boolean().optional(),
  spellcheck: z.boolean().optional(),
  paramset: z.string().optional(),
  facetquery: z.string().optional(),
  facetfield: z.string().optional(),
  facetprefix: z.string().optional(),
  facetcontainsignoreCase: z.boolean().optional(),
  facetlimit: z.string().optional(),
  facetmatches: z.string().optional(),
  facetsort: z.string().optional(),
  facetmincount: z.string().optional(),
  facetmissing: z.boolean().optional(),
});

type FormData = z.infer<typeof formSchema>;

function Solr() {
  const [queryParams, setqueryParams] = useState("");
  console.log(queryParams, "queryParams");

  const { data, isLoading, error } = useSolar(queryParams);

  return (
    <div className="flex ">
      <div className="w-[500px] pl-4 ">
        <RequestHandlerForm
          setqueryParams={(data: string) => setqueryParams(data)}
        />
      </div>
      {isLoading && <p>Loading...</p>}
      <div className="pl-40">
        <JSONPretty id="json-pretty" data={data}></JSONPretty>
      </div>
    </div>
  );
}

const RequestHandlerForm = ({
  setqueryParams,
}: {
  setqueryParams: (queryParams: string) => void;
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  console.log(errors, "errors");

  const facetChecked = watch("facet");
  const onSubmit = (data: FormData) => {
    const queryParams: Record<string, any> = {};

    // Add q and q.op to queryParams if they exist
    if (data.q) {
      queryParams.q = data.q;
      if (data.qvalue) {
        queryParams["q.op"] = data.qvalue;
      }
    }

    // Add fq to queryParams if it exists
    if (data.fq) {
      queryParams.fq = data.fq;
    }

    // Add sort to queryParams if it exists
    if (data.sort) {
      queryParams.sort = data.sort;
    }

    // Add start to queryParams if it exists
    if (data.start !== undefined) {
      queryParams.start = data.start;
    }

    // Add rows to queryParams if it exists
    if (data.rows !== undefined) {
      queryParams.rows = data.rows;
    }

    // Add fl to queryParams if it exists
    if (data.fl) {
      queryParams.fl = data.fl;
    }

    // Add df to queryParams if it exists
    if (data.df) {
      queryParams.df = data.df;
    }

    // Add wt to queryParams if it exists
    if (data.wt) {
      queryParams.wt = data.wt;
    }

    // Add indent to queryParams if it exists
    if (data.indent !== undefined) {
      queryParams.indent = data.indent;
    }

    // Add debugQuery to queryParams if it exists
    if (data.debugQuery !== undefined) {
      queryParams.debugQuery = data.debugQuery;
    }

    // Add defType to queryParams if it exists
    if (data.defType) {
      queryParams.defType = data.defType;
    }

    // Add hl to queryParams if it exists
    if (data.hl !== undefined) {
      queryParams.hl = data.hl;
    }

    // Add facet to queryParams if it exists
    if (data.facet !== undefined) {
      queryParams.facet = data.facet;
    }

    // Add facet-related parameters to queryParams if facet is checked
    if (data.facet) {
      if (data.facetquery) {
        queryParams["facet.query"] = data.facetquery;
      }
      if (data.facetfield) {
        queryParams["facet.field"] = data.facetfield;
      }
      if (data.facetprefix) {
        queryParams["facet.prefix"] = data.facetprefix;
      }
      if (data.facetcontainsignoreCase !== undefined) {
        queryParams["facet.contains.ignoreCase"] = data.facetcontainsignoreCase;
      }
      if (data.facetlimit) {
        queryParams["facet.limit"] = data.facetlimit;
      }
      if (data.facetmatches) {
        queryParams["facet.matches"] = data.facetmatches;
      }
      if (data.facetsort) {
        queryParams["facet.sort"] = data.facetsort;
      }
      if (data.facetmincount) {
        queryParams["facet.mincount"] = data.facetmincount;
      }
      if (data.facetmissing !== undefined) {
        queryParams["facet.missing"] = data.facetmissing;
      }
    }
    const queryString = new URLSearchParams(queryParams).toString();
    console.log(queryString, "queryString");

    setqueryParams(queryString);
    // Make the API request using the constructed URL
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="q">q</Label>
        <Input id="q" {...register("q")} />
        {errors.q && <p className="text-red-500">{errors.q.message}</p>}
      </div>
      <div className="space-y-2">
        <Label htmlFor="papa">q.value</Label>
        <Controller
          control={control}
          name="qvalue"
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger id="resource_type" className="w-full">
                <SelectValue placeholder="Select a q.value" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="OR">OR</SelectItem>
                <SelectItem value="AND">AND</SelectItem>
              </SelectContent>
            </Select>
          )}
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="fq">fqs</Label>
        <Input id="fq" {...register("fq")} />
      </div>
      <div className="space-y-2">
        <Label htmlFor="sort">sort</Label>
        <Controller
          control={control}
          name="sort"
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger id="sort" className="w-full">
                <SelectValue placeholder="Select sort order" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="asc">Ascending</SelectItem>
                <SelectItem value="desc">Descending</SelectItem>
              </SelectContent>
            </Select>
          )}
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="start">start</Label>
        <Input id="start" {...register("start")} />
      </div>
      <div className="space-y-2">
        <Label htmlFor="rows">rows</Label>
        <Input id="rows" {...register("rows")} />
      </div>
      <div className="space-y-2">
        <Label htmlFor="fl">fl</Label>
        <Input id="fl" {...register("fl")} />
      </div>
      <div className="space-y-2">
        <Label htmlFor="df">df</Label>
        <Input id="df" {...register("df")} />
      </div>
      <div className="space-y-2">
        <Label htmlFor="paramset">paramset(s)</Label>
        <Input id="paramset" {...register("paramset")} />
      </div>
      <div className="space-y-2">
        <Label htmlFor="wt">wt</Label>
        <Controller
          control={control}
          name="wt"
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger id="wt" className="w-full">
                <SelectValue placeholder="-------" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="json">json</SelectItem>
                <SelectItem value="xml">xml</SelectItem>
                <SelectItem value="python">python</SelectItem>
                <SelectItem value="ruby">ruby</SelectItem>
                <SelectItem value="php">php</SelectItem>
                <SelectItem value="csv">csv</SelectItem>
              </SelectContent>
            </Select>
          )}
        />
      </div>
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Controller
            control={control}
            name="indent"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                id="indent"
                checked={value}
                onCheckedChange={onChange}
              />
            )}
          />
          <label
            htmlFor="indent"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            indent on
          </label>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Controller
            control={control}
            name="debugQuery"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                id="debugQuery"
                checked={value}
                onCheckedChange={onChange}
              />
            )}
          />
          <label
            htmlFor="debugQuery"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            debugQuery
          </label>
        </div>
      </div>
      <div className="space-y-2">
        <Label htmlFor="defType">defy type</Label>
        <Controller
          control={control}
          name="defType"
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger id="defType" className="w-full">
                <SelectValue placeholder="-----" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="dismax">DisMax</SelectItem>
                <SelectItem value="lucene">Lucene</SelectItem>
                <SelectItem value="edismax">EDisMax</SelectItem>
              </SelectContent>
            </Select>
          )}
        />
      </div>
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Controller
            control={control}
            name="hl"
            render={({ field: { value, onChange } }) => (
              <Checkbox id="hl" checked={value} onCheckedChange={onChange} />
            )}
          />
          <label
            htmlFor="hl"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            hl
          </label>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Controller
            control={control}
            name="facet"
            render={({ field: { value, onChange } }) => (
              <Checkbox id="facet" checked={value} onCheckedChange={onChange} />
            )}
          />
          <label
            htmlFor="facet"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            facet
          </label>
        </div>
      </div>
      {facetChecked && (
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="facetquery">facet.query</Label>
            <Input id="facetquery" {...register("facetquery")} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="facetfield">facet.field</Label>
            <Input id="facetfield" {...register("facetfield")} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="facetprefix">facet.prefix</Label>
            <Input id="facetprefix" {...register("facetprefix")} />
          </div>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Controller
                control={control}
                name="facetcontainsignoreCase"
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    id="facetcontainsignoreCase"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                )}
              />
              <label
                htmlFor="facetcontainsignoreCase"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                facet.contains.ignoreCase
              </label>
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="facetlimit">facet.limit</Label>
            <Input id="facetlimit" {...register("facetlimit")} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="facetmatches">facet.matches</Label>
            <Input id="facetmatches" {...register("facetmatches")} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="facetsort">facet.sort</Label>
            <Input id="facetsort" {...register("facetsort")} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="facetmincount">facet.mincount</Label>
            <Input id="facetmincount" {...register("facetmincount")} />
          </div>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Controller
                control={control}
                name="facetmissing"
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    id="facetmissing"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                )}
              />
              <label
                htmlFor="facetmissing"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                facet.missing
              </label>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-2">
        <Label htmlFor="rawquery">Raw Query Parameters </Label>
        <Textarea id="rawquery" {...register("rawquery")} />
      </div>
      <div className="space-y-2">
        <Label htmlFor="jsonquery">JSON Query </Label>
        <Textarea id="jsonquery" {...register("jsonquery")} />
      </div>
      <Button type="submit">Execute Query</Button>
    </form>
  );
};

export default Solr;

import { cn } from "@/lib/utils";
import React, { useRef, useEffect } from "react";
import { Textarea, TextareaProps } from "../ui/textarea";

const AutoResizeTextarea = ({
  value,
  onChange,
  placeholder,
  className = "",
  ...props
}: TextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      // Reset textarea height to auto to correctly calculate the scrollHeight
      textareaRef.current.style.height = "auto";
      // Set the height to scrollHeight to adjust the height based on content
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <Textarea
      {...props}
      ref={textareaRef}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={cn("w-full overflow-hidden resize-none", className)}
      rows={1}
    />
  );
};

export default AutoResizeTextarea;

import { Column, ColumnDef, Row } from "@tanstack/react-table";

import { statuses } from "./data/data";
import { DataTableColumnHeader } from "./data-table-column-header";
import { Checkbox } from "@/components/ui/checkbox";
import CreateRequest from "./form/create-request";
import useSession from "@/actions/useSession";
// import { DataTableRowActions } from "./data-table-row-actions";

export const ADMIN_EMAILS = ["arjun@metaforms.ai", "arjun@workhack.ai"];

const Header = ({ column }: { column: Column<any, unknown> }) => {
  const session = useSession();
  if (ADMIN_EMAILS.includes(session.user.user_metadata.email)) return <></>;
  return <DataTableColumnHeader column={column} title="xyz" />;
};
const CellComponent = ({ row }: { row: Row<any> }) => {
  const session = useSession();
  if (ADMIN_EMAILS.includes(session.user.user_metadata.email)) return <></>;

  return <CreateRequest id={row.original._id} />;
};

export const columns: ColumnDef<any>[] = [
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="id" />
    ),
    cell: ({ row }) => {
      return <div className="w-[80px]">{row.original._id}</div>;
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "resource_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="resource_id" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.original.resource_id}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.value === row.original.status
      );

      if (!status) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "Permission",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Permission" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center">
          {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )} */}
          <span>{row.original.permission}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "Resource Type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Resource Type" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center">
          {/* {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )} */}
          <span>{row.original.resource_type}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "request_reason",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="request_reason" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center">
          <span>{row.original.request_reason}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "permisson",
    header: ({ column }) => {
      return <Header column={column} />;
    },
    cell: CellComponent,
    enableSorting: false,
    enableHiding: false,
  },
];

import React from "react";
import { useOutlet } from "react-router-dom";
import Loader from "@/components/loader";
import useSession from "@/actions/useSession";
import Login from "@/pages/login";
import Navbar from "@/components/navbar";
import { supabase } from "@/supabaseClient";

const ProtectedRoute = () => {
  const session = useSession();

  const outlet = useOutlet();

  if (session === "loading") {
    return <Loader />;
  }

  if (session && session?.user?.email?.split("@")[1] !== "workhack.ai") {
    return (
      <>
        Not authorized.{" "}
        <a onClick={() => supabase.auth.signOut()} href="/">
          Click here
        </a>{" "}
        to login with a workhack account.
      </>
    );
  }

  if (session) {
    return (
      <div className="max-h-dvh flex flex-col h-dvh">
        <Navbar />
        {outlet}
      </div>
    );
  }

  return <Login />;
};

export default ProtectedRoute;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Switch } from "../ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { LogEvent } from "@/types";
import useQueryLogs from "@/actions/api/useQueryLogs";
import { toast } from "sonner";
import useEventNames from "@/actions/api/useEventNames";
import useDebounce from "@/actions/useDebounce";
import EventSelector from "../event-selector";

export type FiltersType = {
  event_names: string[];
  org_id?: string;
  user_id?: string;
  form_id?: string;
  completion_text?: string;
  error?: boolean;
  exclude_parent_events_without_llm_component_events?: boolean;
};
export type GroupByOptions = undefined | "org_id" | "user_id" | "form_id";

const Filters = ({
  setFilteredLogs,
  currentPage,
  setLoading,
  setTotalLogsCount,
  compactView,
  setCompactView,
}: {
  setFilteredLogs: React.Dispatch<LogEvent[]>;
  setLoading: React.Dispatch<boolean>;
  currentPage: number;
  setTotalLogsCount: React.Dispatch<number>;
  compactView: boolean;
  setCompactView: React.Dispatch<boolean>;
}) => {
  const queryLogs = useQueryLogs({ limit: 10, offset: (currentPage - 1) * 10 });

  const [filters, setFilters] = useState<FiltersType>({
    event_names: [],
    org_id: undefined,
    user_id: undefined,
    form_id: undefined,
    completion_text: undefined,
    error: false,
    exclude_parent_events_without_llm_component_events: false,
  });
  const debouncedFilters = useDebounce(filters);
  const { data: eventNames = [] } = useEventNames();

  const eventNameOptions = useMemo(() => {
    return (
      eventNames?.event_names?.map((name: string) => ({
        label: name,
        value: name,
      })) || []
    );
  }, [eventNames]);

  const [groupBy, setGroupBy] = useState<GroupByOptions>(undefined);

  const onFiltersChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilters((filters) => ({
      ...filters,
      [e.target.name]:
        e.target.name === "event_names" ? [e.target.value] : e.target.value,
    }));
  };

  const handleGroupByChange = (value: string) => {
    setGroupBy(value as GroupByOptions);
  };

  const getFilteredLogs = useCallback(
    async ({
      filters,
      groupBy,
    }: {
      filters: FiltersType;
      groupBy: GroupByOptions;
    }) => {
      setLoading(true);
      queryLogs
        .mutateAsync(filters)
        .then((resp) => {
          toast.success("Logs fetched successfully!");
          setLoading(false);
          console.log(resp);
          setFilteredLogs(resp?.event_groups);
          setTotalLogsCount(resp?.total_count || 1);
        })
        .catch((e) => {
          if (!e.message.startsWith("signal"))
            toast.error("Oops! Something went wrong. Please try again!!!!!");
        });
    },
    // eslint-disable-next-line
    [setFilteredLogs]
  );

  useEffect(() => {
    getFilteredLogs({
      filters: debouncedFilters,
      groupBy,
    });
  }, [debouncedFilters, groupBy, getFilteredLogs, currentPage]);

  return (
    <div className="grid grid-rows-3 grid-flow-col gap-y-2 gap-x-5">
      <div className="col-span-1 ">
        <Label htmlFor="event-name">Event Names</Label>

        <EventSelector
          onChange={(val) =>
            setFilters((filters) => ({
              ...filters,
              event_names: val.map((v: any) => v.value),
            }))
          }
          value={eventNameOptions.filter((opt: any) =>
            filters.event_names.includes(opt.value)
          )}
          options={eventNameOptions}
        />
      </div>
      <div className="col-span-1">
        <Label htmlFor="org-id">Organisation Id</Label>
        <Input
          placeholder="Organisation Id"
          id="org-id"
          name="org_id"
          value={filters.org_id}
          onChange={onFiltersChange}
        />
      </div>
      <div className="col-span-1">
        <Label htmlFor="group-by">Group By</Label>
        <Select
          value={groupBy}
          onValueChange={(value) => handleGroupByChange(value)}
        >
          <SelectTrigger className="" id="group-by">
            <SelectValue placeholder="Group by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="org_id">Organisation Id</SelectItem>
            <SelectItem value="user_id">User Id</SelectItem>
            <SelectItem value="form_id">Form Id</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="col-span-1">
        <Label htmlFor="user-id">User Id</Label>
        <Input
          placeholder="User Id"
          id="user-id"
          name="user_id"
          value={filters.user_id}
          onChange={onFiltersChange}
        />
      </div>
      <div className="col-span-1">
        <Label htmlFor="form-id">Form Id</Label>
        <Input
          placeholder="Form Id"
          id="form-id"
          name="form_id"
          value={filters.form_id}
          onChange={onFiltersChange}
        />
      </div>
      <div className="flex justify-between col-span-2">
        <div className="flex gap-4 items-center">
          <Label htmlFor="filter-error-switch">Error</Label>
          <Switch
            id="filter-error-switch"
            name="error"
            checked={filters.error}
            onCheckedChange={(value) =>
              setFilters((filters) => ({ ...filters, error: value }))
            }
          />
        </div>
        <div className="flex gap-4 items-center">
          <Label htmlFor="filter-exclude_parent_events_without_llm_component_events-switch">
            Exclude events without llm events
          </Label>
          <Switch
            id="filter-exclude_parent_events_without_llm_component_events-switch"
            name="exclude_parent_events_without_llm_component_events"
            checked={filters.exclude_parent_events_without_llm_component_events}
            onCheckedChange={(value) =>
              setFilters((filters) => ({
                ...filters,
                exclude_parent_events_without_llm_component_events: value,
              }))
            }
          />
        </div>
        <div className="flex gap-4 items-center">
          <Label htmlFor="compact-view">Compact View</Label>
          <Switch
            id="compact-view"
            name="compactView"
            checked={compactView}
            onCheckedChange={(value) => setCompactView(value)}
          />
        </div>
      </div>
      <div className="col-span-2 row-span-2 flex flex-col gap-1">
        <Label htmlFor="completion-text">Completion Text</Label>
        <Textarea
          placeholder="Completion Text"
          id="completion-text"
          className="grow"
          name="completion_text"
          value={filters.completion_text}
          onChange={onFiltersChange}
        />
      </div>
    </div>
  );
};

export default Filters;

//@ts-nocheck
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import usePermissionManagepost from "@/actions/api/usepostPermissionManager";

function Acceptrequest() {
  return (
    <div>
      <MyForm />
    </div>
  );
}

export default Acceptrequest;

const formSchema = z.object({
  resource_type: z
    .string()
    .min(1, { message: "Please select a resource type" }),
  collection_name: z.string().optional(),
  account_id: z.string().optional(),
  resource_id: z
    .string()
    .min(1, { message: "Name must be at least 1 characters" }),
  request_reason: z
    .string()
    .min(2, { message: "Name must be at least 2 characters" }),
  permission: z.string().min(1, { message: "Please select a resource type" }),
});

type FormValues = z.infer<typeof formSchema>;

export function MyForm() {
  const [open, setOpen] = useState(false);
  const mutation = usePermissionManagepost();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    mutation.mutate(data);
    reset();
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>ask permission</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Form</DialogTitle>
          <DialogDescription>Please fill out the form below.</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="resource_type">Resource Type</Label>
            <Controller
              control={control}
              name="resource_type"
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger id="resource_type" className="w-full">
                    <SelectValue placeholder="Select a resource type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="redshift_db_access">Redshift</SelectItem>
                    <SelectItem value="mongo_db_access">Mongo</SelectItem>
                    <SelectItem value="account_access">
                      Account Access
                    </SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
            {errors.resource_type && (
              <p className="text-red-500">{errors.resource_type.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="resource_id">ORG ID</Label>
            <Input id="resource_id" {...register("resource_id")} />
            {errors.resource_id && (
              <p className="text-red-500">{errors.resource_id.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="request_reason">Request Reason</Label>
            <Textarea id="request_reason" {...register("request_reason")} />
            {errors.request_reason && (
              <p className="text-red-500">{errors.request_reason.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="permission">Permission</Label>
            <Controller
              control={control}
              name="permission"
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger id="permission" className="w-full">
                    <SelectValue placeholder="Select a permission" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="read">Read</SelectItem>
                    <SelectItem value="write">Write</SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
            {errors.permission && (
              <p className="text-red-500">{errors.permission.message}</p>
            )}
          </div>
          {/* 
          <div className="space-y-2">
            <Label htmlFor="ttl">Time to Leave</Label>
            <Input id="ttl" type="number" {...register("ttl")} />
            {errors.ttl && <p className="text-red-500">{errors.ttl.message}</p>}
          </div> */}

          <DialogFooter>
            <Button type="submit">Submit</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import usePermissionManagepostAdmin from "@/actions/api/usepostPermissionManagerAdmin";

function CreateRequest({ id }: { id: string }) {
  return (
    <div>
      <MyForm id={id} />
    </div>
  );
}

export default CreateRequest;

const formSchema = z.object({
  status: z.enum(["approved", "rejected"]),
  status_reason: z
    .string()
    .min(2, { message: "Status reason must be at least 2 characters" }),
});

type FormValues = z.infer<typeof formSchema>;

export function MyForm({ id }: { id: string }) {
  console.log(id);

  const [open, setOpen] = useState(false);
  const mutation = usePermissionManagepostAdmin();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    mutation.mutate({ formData: { ...data, ttl: 43200 }, id });
    reset();
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>permission</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Form</DialogTitle>
          {id}
          <DialogDescription>Please fill out the form below.</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="status">Status</Label>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger id="status" className="w-full">
                    <SelectValue placeholder="Select a status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="approved">Approved</SelectItem>
                    <SelectItem value="rejected">Rejected</SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
            {errors.status && (
              <p className="text-red-500">{errors.status.message}</p>
            )}
          </div>

          {/* <div className="space-y-2">
            <Label htmlFor="ttl">Time to appprove</Label>
            <Input id="ttl" type="number" {...register("ttl")} />
            {errors.ttl && <p className="text-red-500">{errors.ttl.message}</p>}
          </div> */}

          <div className="space-y-2">
            <Label htmlFor="status_reason">Status Reason</Label>
            <Textarea id="status_reason" {...register("status_reason")} />
            {errors.status_reason && (
              <p className="text-red-500">{errors.status_reason.message}</p>
            )}
          </div>

          <DialogFooter>
            <Button type="submit">Submit</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

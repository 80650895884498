import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import useAddNewPromptTemplate from '@/actions/api/useAddNewPromptTemplate';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClick: () => void;
};

const NewPromptDialog = ({ onClick }: Props) => {
  const addNewPrompt = useAddNewPromptTemplate();
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    await addNewPrompt
      .mutateAsync({ name })
      .then(() => {
        toast.success('Prompt template added successfully!');
        onClick();
        navigate(`/playground/${name}`);
      })
      .catch(() => {
        toast.error('Oops! Something went wrong. Please try again');
      });
  };

  return (
    <div>
      <DialogHeader>
        <DialogTitle>Add a new prompt</DialogTitle>
      </DialogHeader>

      <div className='my-5'>
        <Label>Name of the prompt</Label>
        <Input
          placeholder='Name of the prompt'
          name={name}
          onChange={e => setName(e.target.value)}
        />
      </div>

      <DialogFooter>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogFooter>
    </div>
  );
};

export default NewPromptDialog;

import React, { useCallback, useEffect, useRef, useState } from "react";
import HightLevelEventBox from "@/components/event-box/high-level";
import { useParams } from "react-router-dom";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

import { ClipboardPlus, SquareArrowOutUpRight } from "lucide-react";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { LogEvent } from "@/types";
import DynamicObject from "@/components/dynamic-object";
import useQueryLogs from "@/actions/api/useQueryLogs";
import { toast } from "sonner";
import CompactDataLog from "./compact-view";
import config from "@/config";

type Props = {
  event?: LogEvent;
  expandedEvent?: boolean;
  setExpandedEvent?: () => void;
  compactView?: boolean;
};

const ParticularLogPage = ({
  setExpandedEvent = () => {},
  compactView = false,
  ...props
}: Props) => {
  const { trace_id } = useParams();
  const triggerRef = useRef<HTMLDivElement>(null);
  const queryLogs = useQueryLogs({ limit: 1, offset: 0 });
  const [event, setEvent] = useState<LogEvent>({});

  const fetchEvent = useCallback(
    (trace_id: string) => {
      if (!trace_id) return {};
      queryLogs
        .mutateAsync({ sentry_trace_id: trace_id })
        .then((resp) => {
          toast.success("Log fetched successfully!");
          setEvent(resp?.event_groups?.[0]);
        })
        .catch(() => {
          toast.error("Oops! Something went wrong. Please try again");
        });
    },
    // eslint-disable-next-line
    []
  );

  const getEventUrl = (event?: any) => {
    return `${config.web_url}/logs/${event?.parent_event?.sentry_trace_id}`;
  };

  const handleToggleCollapse = () => {
    setTimeout(() => {
      if (!!triggerRef.current) {
        triggerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 0);
  };

  useEffect(() => {
    if (props?.event) setEvent(props.event);
    else fetchEvent(trace_id as string);
  }, [trace_id, props.event, fetchEvent]);

  return (
    <div
      className={cn(
        "border rounded",
        props.expandedEvent && "border-slate-400"
      )}
    >
      <Collapsible
        open={props.expandedEvent ?? true}
        onOpenChange={() => setExpandedEvent()}
      >
        <CollapsibleTrigger
          className="w-full text-left"
          onClick={handleToggleCollapse}
        >
          <HightLevelEventBox
            ref={triggerRef}
            event={event?.parent_event}
            className="border-0 rounded-none pointer-events-none	"
          />
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="p-4 flex flex-col gap-2 border-t">
            {!trace_id && (
              <div className="flex gap-5 justify-end">
                <Button
                  onClick={() =>
                    navigator.clipboard.writeText(getEventUrl(event))
                  }
                >
                  Copy url
                  <ClipboardPlus className="w-5 ml-2" />
                </Button>
                <Button onClick={() => window.open(getEventUrl(event))}>
                  Open
                  <SquareArrowOutUpRight className="w-5 ml-2" />
                </Button>
              </div>
            )}
            {compactView ? (
              <CompactDataLog event={event} />
            ) : (
              <DynamicObject event={event} />
            )}
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export default ParticularLogPage;

import React, { useMemo } from "react";

import DynamicObject from "@/components/dynamic-object";

const CompactDataLog = ({ event }: { event: any }) => {
  const { org_id, form_id, user_id, sentry_trace_id } =
    event.parent_event || {};
  const llm_events = useMemo(() => {
    if (!event) return {};
    return event.llm_component_events?.map((sub_event: any) => ({
      params:
        typeof sub_event.event.intermediate_outputs?.[0] === "string"
          ? sub_event.event.params
          : sub_event.event.intermediate_outputs?.[0],
      associated_event_name: sub_event.llm_event.associated_event_name,
      timestamp: sub_event.llm_event.timestamp,
      prompt: sub_event.llm_event.prompt,
      completion: sub_event.llm_event.completion,
      first_token_ms: sub_event.llm_event.first_token_ms,
      duration_ms: sub_event.llm_event.duration_ms,
    }));
  }, [event]);

  return (
    <>
      <DynamicObject
        event={{
          org_id,
          form_id,
          user_id,
          llm_events,
        }}
        sentryTraceId={sentry_trace_id}
        compactView={true}
      />
    </>
  );
};

export default CompactDataLog;

import { DataTable } from "./components/data-table";
import { ADMIN_EMAILS, columns } from "./components/column";
import Acceptrequest from "./components/form/accept-request";
import usePermissionManagerAdmin from "@/actions/api/usePermissionManagerAdmin";
import useSession from "@/actions/useSession";

function Hipa() {
  return (
    <div className="p-4">
      <div className="flex justify-end w-full mb-4">
        <Acceptrequest />
      </div>
      <Table />
    </div>
  );
}

export function Table() {
  const session = useSession();

  const { isLoading: adminloading, data: permissiondata } =
    usePermissionManagerAdmin(
      ADMIN_EMAILS.includes(session.user.user_metadata.email)
    );

  if (adminloading) return <></>;

  const valuedata = permissiondata;
  return <DataTable columns={columns} data={valuedata} />;
}

export default Hipa;

import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import DateTimePicker from '@/components/datetime-picker';

const ReleaseDataDialog = ({
  title,
  data
}: {
  title: string;
  data?: {
    datetime: string;
    name: string;
  };
}) => {
  const [datetime, setDatetime] = useState<string>(data?.datetime || '');
  const [name, setName] = useState(data?.name || '');

  return (
    <div>
      <DialogHeader>
        <DialogTitle className='text-capitalize'>{title}</DialogTitle>
      </DialogHeader>
      <div className='my-5 flex flex-col gap-3'>
        <div>
          <Label>Name</Label>
          <Input
            placeholder='Name of the release'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <div>
          <Label>Date</Label>
          <DateTimePicker datetime={datetime} setDatetime={setDatetime} />
        </div>
      </div>
      <DialogFooter>
        <Button variant='outline'>Cancel</Button>
        <Button>Create</Button>
      </DialogFooter>
    </div>
  );
};

export default ReleaseDataDialog;

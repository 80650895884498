import React, { createContext, useContext, useState, useEffect } from "react";

import { supabase } from "../supabaseClient";
import { Session } from "@supabase/supabase-js";

const SessionContext = createContext<any>("loading");

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [session, setSession] = useState<Session | string | null>("loading");

  useEffect(() => {
    supabase.auth
      .getSession()
      .then((session) => {
        console.log("session", session)
        setSession(session.data.session);
      })
      .catch(() => {
        setSession(null);
      });
    supabase.auth.onAuthStateChange((_, session) => {
      setSession(session);
    });
  }, []);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = () => useContext(SessionContext);

export default useSession;

import AddDatasetButton from './add-dataset-button';

import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import useDatasets from '@/actions/api/usePromptDatasets';
import { Dataset } from './particular-dataset';
import { useNavigate } from 'react-router-dom';

const Datasets = () => {
  const navigate = useNavigate();
  const { data: datasets = [] } = useDatasets();

  return (
    <div className='p-5 min-h-dvh max-h-dvh flex flex-col'>
      <div className='flex justify-between items-center mb-5'>
        <h3 className='text-2xl font-semibold'>Datasets</h3>
        <AddDatasetButton />
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className=''>Name</TableHead>
            <TableHead className='text-center'>Version</TableHead>
            <TableHead className='text-right'>Timestamp</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {datasets?.map((dataset: Dataset) => (
            <TableRow
              key={dataset._id}
              onClick={() => navigate(`${dataset._id}`)}
              className='cursor-pointer'
            >
              <TableCell>{dataset.name}</TableCell>
              <TableCell className='text-center'>
                {dataset.version || '-'}
              </TableCell>
              <TableCell className='text-right'>
                {moment(dataset.timestamp).format('DD-MM-YYYY')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Datasets;

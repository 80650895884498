import React from 'react';
import { Button } from '@/components/ui/button';
import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';

const DeleteConfirfationDialog = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <div>
      <DialogHeader>
        <DialogTitle>Are you sure to delete this prompt?</DialogTitle>
        <DialogFooter>
          <DialogClose>
            <Button variant='outline'>Cancel</Button>
          </DialogClose>
          <DialogClose>
            <Button onClick={onDelete}>Delete</Button>
          </DialogClose>
        </DialogFooter>
      </DialogHeader>
    </div>
  );
};

export default DeleteConfirfationDialog;

import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

export const usePromptVersion = (
  name: string,
  version: number,
  draftName: string
) => {
  const fetchWithAuth = useFetchWithAuth();

  let url: string = "";
  if (!draftName)
    url = `${
      config.backend_url
    }/prompt_templates/${name}/version/${version.toString()}`;
  else
    url = `${config.backend_url}/prompt_templates/${name}/draft/${draftName}`;

  const fetchPromptVersion = async () => {
    if (!name || version === -1) {
      return null;
    }

    const res = await fetchWithAuth(url).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useQuery({
    queryKey: ["prompt_version", name, version, draftName],
    queryFn: fetchPromptVersion,
  });
};

import React from "react";
import { createBrowserRouter } from "react-router-dom";
import LogsPage from "@/pages/logs";

import ParticularLogPage from "@/pages/logs/particular-log";
import Playground from "@/pages/playground";
import PromptsListPage from "@/pages/prompts-list";
import ReleasePage from "@/pages/release";
import HomePage from "@/pages/home";
import LLmRatingConfigPage from "@/pages/llm-rating-config";
import Datasets from "@/pages/datasets";
import ParticularDataset from "@/pages/datasets/particular-dataset";
import ProtectedRoute from "./protected-route";
import Hipa from "@/pages/hipa";
import Editor from "@/pages/editor";
import Redshift from "@/pages/redshift";
import UserAcess from "@/pages/useracess";
import Solr from "@/pages/solr";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "logs",
        element: <LogsPage />,
        children: [
          {
            path: ":trace_id",
            element: <ParticularLogPage />,
          },
        ],
      },
      {
        path: "playground",
        element: <Playground />,
      },
      {
        path: "playground/:prompt_name",
        element: <Playground />,
      },
      {
        path: "prompts",
        element: <PromptsListPage />,
      },
      {
        path: "release",
        element: <ReleasePage />,
      },
      {
        path: "llm-rating-config",
        element: <LLmRatingConfigPage />,
      },
      {
        path: "dataset/",
        element: <Datasets />,
      },
      {
        path: "dataset/:dataset_id",
        element: <ParticularDataset />,
      },
      {
        path: "permission/",
        element: <Hipa />,
      },
      {
        path: "editor/",
        element: <Editor />,
      },
      {
        path: "redshift/",
        element: <Redshift />,
      },
      {
        path: "useracess/",
        element: <UserAcess />,
      },
      {
        path: "solr/",
        element: <Solr />,
      },
    ],
  },
]);

export default router;

import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

const useLLMRatingParameters = () => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchRatingParameters = async () => {
    const url = `${config.backend_url}/llm/rating_parameters/`;
    const res = await fetchWithAuth(url).then((res) => res.json());

    if (res?.status === "error") {
      throw new Error(res?.description);
    }

    return res;
  };

  return useQuery({
    queryKey: ["llm_rating_parameters"],
    queryFn: fetchRatingParameters,
  });
};

export default useLLMRatingParameters;

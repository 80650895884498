import React, { useState } from "react";
import LabeledSection from "../labeled-section";
import { Button } from "../ui/button";
import { SquareArrowOutUpRight } from "lucide-react";
import CompletionText from "../llm-response";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import Playground from "@/pages/playground";

const DynamicObject = ({
  event,
  sentryTraceId = "",
  compactView = false,
  className = "",
}: {
  event: any;
  sentryTraceId?: string;
  compactView?: boolean;
  className?: string;
}) => {
  const dataTypes = ["string", "number", "boolean"];
  const size = typeof event === "object" ? Object.entries(event).length : 0;

  const [collapsedOne, setCollapsedOne] = useState<boolean[]>(
    [...Array(size)].map(() => true)
  );
  const [collapsedTwo, setCollapsedTwo] = useState<boolean[]>(
    [...Array(size)].map(() => true)
  );
  const [collapsedThree, setCollapsedThree] = useState<boolean[]>(
    [...Array(size)].map(() => true)
  );

  const getPlaygroundState = ({ prompt, variables }: any) => {
    const variableList = Object.entries(variables ?? {}).map((variable) => ({
      key: variable[0],
      value:
        Array.isArray(variable[1]) && typeof variable[1][0] === "string"
          ? variable[1].reduce((finalValue, curr, currIndex) => {
              if (currIndex === 0) {
                return `['${curr}', `;
              } else if (currIndex === (variable[1] as string[]).length - 1) {
                return finalValue + `'${curr}']`;
              }
              return finalValue + `'${curr}', `;
            }, "")
          : variable[1],
    }));

    return {
      prompt,
      variables: variableList,
    };
  };

  return (
    <>
      {/* If item is string | number | boolean we can directly print it */}
      {dataTypes.includes(typeof event) ? (
        <p className="max-w-full min-w-[200px] overflow-auto inline-block whitespace-pre-line">
          {event}
        </p>
      ) : (
        <div className="grid grid-cols-3 gap-2">
          {Object.keys(event || {}).map((key, ind) => {
            // Skipping the key
            if (
              event[key] === undefined ||
              event[key] === null ||
              // Empty string or empty array
              ((typeof event[key] === "string" || Array.isArray(event[key])) &&
                !event[key].length)
            )
              return null;

            if (compactView && key === "params") return null;

            // For primitive data types
            if (dataTypes.includes(typeof event[key])) {
              return key === "completion" ? (
                <LabeledSection label={key} key={ind} className="col-span-3">
                  <CompletionText
                    text={event[key].toString()}
                    llmEvent={event}
                    sentryTraceId={sentryTraceId}
                  />
                </LabeledSection>
              ) : (
                <LabeledSection
                  label={key}
                  key={ind}
                  className={cn(
                    "col-span-1",
                    event[key].toString().length > 800 && "col-span-2",
                    event[key].toString().length > 1500 && "col-span-3",
                    className
                  )}
                >
                  <DynamicObject
                    event={event[key].toString()}
                    compactView={compactView}
                    sentryTraceId={sentryTraceId}
                  />
                </LabeledSection>
              );
            }

            // For arrays
            if (Array.isArray(event[key])) {
              return key === "prompt" ? (
                /* For displaying only the last message of a prompt */
                <LabeledSection
                  label={key}
                  key={ind}
                  collapsed={collapsedOne[ind]}
                  onChangeCollapsed={(val: boolean) =>
                    setCollapsedOne((curr) =>
                      curr.map((v, i) => (i === ind ? val : v))
                    )
                  }
                  className="col-span-3"
                >
                  <DynamicObject
                    event={{
                      content: event[key][event[key].length - 1].content,
                    }}
                    compactView={compactView}
                    className="col-span-3"
                    sentryTraceId={sentryTraceId}
                  />
                </LabeledSection>
              ) : (
                <LabeledSection
                  label={key}
                  key={ind}
                  collapsed={collapsedTwo[ind]}
                  onChangeCollapsed={(val: boolean) =>
                    setCollapsedTwo((curr) =>
                      curr.map((v, i) => (i === ind ? val : v))
                    )
                  }
                  className="col-span-3 gap-2"
                >
                  <div className="grid gap-2 grid-cols-subgrid col-span-3">
                    {event[key].map((item: any, index: number) => (
                      <DynamicObject
                        event={{ [`${key}[${index.toString()}]`]: item }}
                        key={index}
                        compactView={compactView}
                        sentryTraceId={sentryTraceId}
                      />
                    ))}
                  </div>
                </LabeledSection>
              );
            }

            // For Objects
            return (
              <LabeledSection
                label={key}
                key={ind}
                collapsed={collapsedThree[ind]}
                onChangeCollapsed={(val: boolean) =>
                  setCollapsedThree((curr) =>
                    curr.map((v, i) => (i === ind ? val : v))
                  )
                }
                // flex: Only to shift Playgroud button to right
                className="col-span-3 flex flex-col gap-2 "
              >
                {/* Checking if the current event is an LLM Event */}
                {key.startsWith("llm_event") ? (
                  /* For adding the Playgroung button for each llm event */
                  <>
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button className="self-end">
                          Playground
                          <SquareArrowOutUpRight className="w-5 ml-2" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="min-w-dvw max-w-none min-h-dvh p-0">
                        <Playground
                          state={getPlaygroundState({
                            prompt: event[key].prompt,
                            variables:
                              event["event"]?.intermediate_outputs[0] ||
                              event[key]["params"],
                          })}
                        />
                      </DialogContent>
                    </Dialog>
                    <DynamicObject
                      event={event[key]}
                      sentryTraceId={
                        sentryTraceId ||
                        event["event"]?.["sentry_trace_id"] ||
                        ""
                      }
                      compactView={compactView}
                    />
                  </>
                ) : (
                  <DynamicObject
                    event={event[key]}
                    compactView={compactView}
                    sentryTraceId={sentryTraceId}
                  />
                )}
              </LabeledSection>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DynamicObject;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

interface PermissionManagerPayload {
  resource_type: string;
  resource_id: string;
  ttl: number;
  request_reason: string;
  permission: string;
}

const usePermissionManagepost = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["permission_manager"];

  const usepostData = async (formData: PermissionManagerPayload) => {
    const url = `${config.backend_url}/permission-manager`;
    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    const res = await fetchWithAuth(url, payload).then((res) => res.text());
    return res;
  };

  return useMutation({
    mutationFn: usepostData,
    onMutate: async (newFormData) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...newFormData,
      }));

      return { previousFormData };
    },

    onError: (err, newFormData, context) => {
      queryClient.setQueryData(cacheKey, context?.previousFormData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default usePermissionManagepost;

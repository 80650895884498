import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

const useDatasets = () => {
  const fetchWithAuth = useFetchWithAuth();

  const url = config.backend_url + "/prompt_dataset/";

  const fetchDatasets = async () => {
    const res = await fetchWithAuth(url).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useQuery({
    queryKey: ["prompt_datasets"],
    queryFn: fetchDatasets,
  });
};

export default useDatasets;

import React, { useMemo, useState } from 'react';
import useEventNames from '@/actions/api/useEventNames';
import EventSelector from '@/components/event-selector';
import { Label } from '@/components/ui/label';
import { DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { AttributeType } from '.';
import { Input } from '@/components/ui/input';

const NewLlmConfigDialog = ({
  handleSubmitNewLlmConfig
}: {
  handleSubmitNewLlmConfig: (eventName: string) => void;
}) => {
  const defaultAttribute = {
    name: '',
    min: 0,
    max: 0
  };

  const { data: eventNames = [] } = useEventNames();
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [attributes, setAttributes] = useState<AttributeType[]>([
    defaultAttribute
  ]);

  const eventNameOptions = useMemo(() => {
    return (
      eventNames?.event_names?.map((name: string) => ({
        label: name,
        value: name
      })) || []
    );
  }, [eventNames]);

  const handleSave = () => {
    handleSubmitNewLlmConfig(selectedEvent.value);
  };

  return (
    <div className='flex flex-col gap-5'>
      <div className='w-full'>
        <Label>Choose an Event</Label>
        <EventSelector
          onChange={val => setSelectedEvent(val)}
          value={selectedEvent}
          options={eventNameOptions}
          isMulti={false}
          className='max-w-full'
        />
      </div>

      <div>
        <p className='font-semibold'>Attributes to rate on</p>
        <div className='flex flex-col gap-4 mt-2'>
          {attributes.map((attribute, ind) => (
            <div className='flex gap-4'>
              <div className='basis-2/4'>
                <Label>Parameter</Label>
                <Input
                  value={attribute.name}
                  placeholder='Name of the parameter'
                  name='name'
                />
              </div>
              <div className='basis-1/4'>
                <Label>Min Rating</Label>
                <Input
                  value={attribute.name}
                  type='number'
                  placeholder='0'
                  name='min'
                />
              </div>
              <div className='basis-1/4'>
                <Label>Max Rating</Label>
                <Input
                  value={attribute.name}
                  type='number'
                  placeholder='0'
                  name='max'
                />
              </div>
            </div>
          ))}
          <Button
            onClick={() => setAttributes(curr => [...curr, defaultAttribute])}
          >
            Add Attribute
          </Button>
        </div>
      </div>

      <DialogFooter className=''>
        <Button onClick={handleSave}>Save</Button>
      </DialogFooter>
    </div>
  );
};

export default NewLlmConfigDialog;

import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

const usePrompts = (promptStatus: "active" | "archive") => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchPrompts = async () => {
    const url = `${config.backend_url}/prompt_templates/detail?status=${promptStatus}`;
    const res = await fetchWithAuth(url).then((res) => res.json());

    if (res?.status === "error") {
      throw new Error(res?.description);
    }

    return res;
  };

  return useQuery({
    queryKey: ["prompts", promptStatus],
    queryFn: fetchPrompts,
  });
};

export default usePrompts;

import React, { useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "@/components/ui/select";
import { Trash2 } from "lucide-react";
import AsyncSelect from "react-select/async";
import AutoResizeTextarea from "@/components/auto-resize-textarea";

export type VariableType = {
  key: string;
  value: string;
  type?: string;
};

const VariableList = ({
  variableList = [],
}: {
  variableList: VariableType[];
}) => {
  // const variableTypes = ["date/time", "model/schema", "custom"];
  const defaultVariable = useMemo(
    () => ({
      key: "",
      value: "",
      type: "custom",
    }),
    []
  );
  const [variables, setVariables] = useState<
    {
      key: string;
      value: string;
      type: string;
    }[]
  >(() => {
    if (variableList.length === 0) return [defaultVariable];
    return variableList.map((variable) => ({
      ...variable,
      type: variable.type ?? "custom",
    }));
  });

  // const handleTypeChange = (type: string, index: number) => {
  //   setVariables((variables) =>
  //     variables.map((variable, i) => {
  //       if (index !== i) return variable;

  //       if (type === "date/time")
  //         return { ...variable, type: type, key: "datetime" };

  //       return { ...variable, type: type };
  //     })
  //   );
  // };

  const promiseOptions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      // TODO: Will fetch model/schema from backend
      setTimeout(() => {
        resolve([
          {
            label: "some schema",
            value: "some_schema",
          },
        ]);
      }, 1000);
    });

  return (
    <div className="h-full flex flex-col gap-4 ">
      <div className=" grow flex flex-col gap-4 overflow-y-scroll">
        {variables.map((variable, ind) => (
          <div key={ind} className="flex gap-4 p-2 relative">
            {/* <div className="basis-13">
              <Label>Type</Label>
              <Select
                value={variable.type}
                onValueChange={(value) => handleTypeChange(value, ind)}
              >
                <SelectTrigger className="w-[80px]">
                  <SelectValue placeholder="Type" />
                </SelectTrigger>
                <SelectContent>
                  {variableTypes.map((type) => (
                    <SelectItem value={type} key={type} className="capitalize">
                      {type}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div> */}
            <div className="basis-1/3">
              <Label>Key</Label>
              {["date/time", "custom"].includes(variable.type) && (
                <Input
                  value={variable.key}
                  onChange={(e) =>
                    setVariables((variables) =>
                      variables.map((variable, i) =>
                        i === ind
                          ? { ...variable, key: e.target.value }
                          : variable
                      )
                    )
                  }
                  disabled={variable.type === "date/time"}
                  placeholder="Key"
                />
              )}
              {variable.type === "model/schema" && (
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  className="min-w-[300px]"
                />
              )}
            </div>
            {variable.type === "custom" && (
              <div className="basis-2/3 grow">
                <Label>Value</Label>
                <AutoResizeTextarea
                  value={variable.value}
                  onChange={(e) =>
                    setVariables((variables) =>
                      variables.map((variable, i) =>
                        i === ind
                          ? { ...variable, value: e.target.value }
                          : variable
                      )
                    )
                  }
                  placeholder="Value"
                />
              </div>
            )}
            <div
              className="absolute right-0 top-0 cursor-pointer"
              onClick={() =>
                setVariables((variables) =>
                  variables.filter((_, i) => i !== ind)
                )
              }
            >
              <Trash2
                className="w-4 text-red-600 "
                onClick={() =>
                  setVariables((variables) =>
                    variables.filter((_, i) => i !== ind)
                  )
                }
              />
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-flow-row grid-cols-1 gap-x-5 gap-y-2">
        <Button
          className=""
          onClick={() =>
            setVariables((variables) => [...variables, defaultVariable])
          }
        >
          Add Variable
        </Button>
      </div>
    </div>
  );
};

export default VariableList;

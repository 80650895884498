import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

const useDataset = (id: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const url = config.backend_url + "/prompt_dataset/" + id;

  const fetchDataset = async () => {
    if (!id) {
      return null;
    }
    const res = await fetchWithAuth(url).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useQuery({
    queryKey: ["prompt_dataset", id],
    queryFn: fetchDataset,
  });
};

export default useDataset;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

export type Feedback = {
  org_id: string;
  query: string;
  table: string;
};

const usePostRedShift = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["redshift-interface"];

  const updateFormData = async ({ data }: { data: Feedback }) => {
    let url = config.backend_url + "/redshift-interface/";
    let payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    };
    const res = await fetchWithAuth(url, payload).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useMutation({
    mutationFn: updateFormData,
    onMutate: async (param) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...param,
      }));

      return { previousFormData };
    },

    onError: (err, param, context: any) => {
      queryClient.setQueryData(cacheKey, context.previousFormData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default usePostRedShift;

import React, { useState } from 'react';

import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';

type Props = {
  handleSavePromptAsLatest: ({
    commitMessage
  }: {
    commitMessage: string;
  }) => Promise<void>;
  handleSaveAsDraft?: ({
    commitMessage,
    draftName
  }: {
    commitMessage: string;
    draftName: string;
  }) => Promise<void>;
};

const SavePromptDialog = ({
  handleSavePromptAsLatest,
  handleSaveAsDraft
}: Props) => {
  const [asDraft, setAsDraft] = useState(false);
  const [commitMessage, setCommitMessage] = useState('');
  const [draftName, setDraftName] = useState('');

  const handleCommitAsDraft = () => {
    if (!asDraft) {
      setAsDraft(true);
      return;
    }
    handleSaveAsDraft?.({ commitMessage, draftName });
  };

  return (
    <div>
      <DialogHeader>
        <DialogTitle>Save the Prompt</DialogTitle>
      </DialogHeader>
      <div className='flex flex-col gap-3 my-5'>
        <div>
          <Label>Add a commit message</Label>
          <Input
            placeholder='commit message'
            value={commitMessage}
            onChange={e => setCommitMessage(e.target.value)}
          />
        </div>

        {asDraft && (
          <div>
            <Label>Add a draft name</Label>
            <Input
              placeholder='draft name'
              value={draftName}
              onChange={e => setDraftName(e.target.value)}
            />
          </div>
        )}
      </div>

      <DialogFooter>
        {handleSaveAsDraft && (
          <Button onClick={handleCommitAsDraft}>Commit as draft</Button>
        )}
        <DialogClose>
          <Button onClick={() => handleSavePromptAsLatest({ commitMessage })}>
            Commit as latest
          </Button>
        </DialogClose>
      </DialogFooter>
    </div>
  );
};

export default SavePromptDialog;

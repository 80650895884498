import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

const useRestorePromptTemplate = (name: string) => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["prompt_template_restore", name];

  const handleRestoreFunction = async (formData: { version: number }) => {
    let url = config.backend_url + "/prompt_templates/restore/" + name;

    let payload = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const res = await fetchWithAuth(url, payload).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useMutation({
    mutationFn: handleRestoreFunction,
    onMutate: async (newPromptTemplate) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...newPromptTemplate,
      }));

      return { previousFormData };
    },

    onError: (err, newPromptTemplate, context: any) => {
      queryClient.setQueryData(cacheKey, context.previousFormData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default useRestorePromptTemplate;

//@ts-nocheck
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";

import { Textarea } from "@/components/ui/textarea";
import { useForm } from "react-hook-form";
import { JsonEditor } from "json-edit-react";
import { Label } from "@/components/ui/label";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useMongo from "@/actions/api/usemongo";
import useMongoDelete from "@/actions/api/usemongodelete";
import useMongoDPost from "@/actions/api/usemongopost";

export const formSchema = z.object({
  org_id: z.string().min(1, "Organization ID is required"),
  query: z.string().min(1, "Query is required"),
  collection: z.string().min(1, "collection is required"),
});

export type FormData = z.infer<typeof formSchema>;

function Editor() {
  const [selectedData, setSelectedData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const { mutate, data } = useMongo();
  const { mutate: mongoDelete, data: mongoData } = useMongoDelete();
  const { mutate: mongoPost } = useMongoDPost();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data: FormData) => {
    console.log(data, "data", {
      org_id: "workhack",
      collection: "orgs",
      query: {},
    });

    mutate({
      data: {
        collection: data.collection,
        org_id: data.org_id,
        query: JSON.parse(data.query),
      },
    });
  };

  const onUpdate = (data: any) => {
    setEditedData(data);
  };

  const handleEdit = (data: any) => {
    console.log(data);

    setSelectedData(data);
  };

  const handleSave = () => {
    console.log(selectedData?._id?.["$oid"] as string);

    mongoPost({
      data: {
        collection: getValues().collection,
        org_id: getValues().collection,
        document_id: (selectedData?._id?.["$oid"] as string) ?? "papa",
        update: editedData.newData,
      },
    });
    setSelectedData(null);
    setEditedData(null);
  };

  const handleDelete = async (document_id) => {
    console.log(document_id, "mongoData");
    console.log({
      collection: getValues().collection,
      org_id: getValues().org_id,
      document_id: document_id?._id?.["$oid"] as string,
    });

    mongoDelete({
      data: {
        collection: getValues().collection,
        org_id: getValues().collection,
        document_id: document_id?._id?.["$oid"] as string,
      },
    });
  };

  return (
    <>
      <Card>
        <form className="pl-5 py-4 " onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="mt-4">
              <Label htmlFor="query" className="mb-4">
                Collection
              </Label>
              <Input
                id="collection"
                {...register("collection")}
                className="w-[500px] my-3  "
              />
              {errors.collection && (
                <p className="text-red-500">{errors.collection.message}</p>
              )}
            </div>
            <Label htmlFor="org_id" className="mb-4">
              Organization ID
            </Label>
            <Input
              id="org_id"
              className="w-[500px] mt-3  "
              {...register("org_id")}
            />
            {errors.org_id && (
              <p className="text-red-500">{errors.org_id.message}</p>
            )}
          </div>
          <div className="mt-4">
            <Label htmlFor="query" className="mb-4">
              Query
            </Label>
            <Textarea
              id="query"
              {...register("query")}
              className="w-[90%] mt-3 "
            />
            {errors.query && (
              <p className="text-red-500">{errors.query.message}</p>
            )}
          </div>
          <Button type="submit" className="mt-4">
            Submit
          </Button>
        </form>
      </Card>
      <div className="grid grid-cols-2 gap-4">
        <div>
          {data?.map((value, i) => (
            <Card key={i} className="mb-4">
              <div className="flex justify-between items-center  pr-4 ">
                <CardHeader>
                  <CardTitle>Data {i + 1}</CardTitle>
                </CardHeader>
                <Button onClick={() => handleEdit(value)}>Edit</Button>
                <Button onClick={() => handleDelete(value)}>Delete</Button>
              </div>
              <CardContent>
                <pre className="overflow-hidden">
                  {JSON.stringify(value, null, 2)}
                </pre>
              </CardContent>
            </Card>
          ))}
        </div>
        <div>
          {selectedData && (
            <Card>
              <div className="flex justify-between items-center  pr-4 ">
                <CardHeader>
                  <CardTitle>Edit Data</CardTitle>
                </CardHeader>
                <Button onClick={handleSave} className="mt-4">
                  Save
                </Button>
              </div>
              <CardContent>
                <JsonEditor
                  className="w-full"
                  data={selectedData}
                  onUpdate={onUpdate}
                />
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default Editor;

import { cn } from '@/lib/utils';
import React from 'react';

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible';

const LabeledSection = ({
  children,
  label,
  className = '',
  collapsed,
  onChangeCollapsed
}: {
  children?: React.ReactNode;
  label?: string;
  className?: string;
  collapsed?: boolean;
  onChangeCollapsed?: any;
}) => {
  return (
    <Collapsible
      open={!collapsed}
      onOpenChange={val => collapsed !== undefined && onChangeCollapsed(!val)}
      asChild
    >
      <div className={cn('border rounded relative p-4 ', className)}>
        <CollapsibleTrigger>
          <p
            className={cn(
              'bg-white px-3 leading-3 cursor-pointer ',
              !collapsed && 'absolute top-[-8px] text-sm text-muted-foreground',
              collapsed && 'text-md text-left'
            )}
          >
            {label}
          </p>
        </CollapsibleTrigger>
        <CollapsibleContent asChild>
          <>{!collapsed && (children ?? <></>)}</>
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
};

export default LabeledSection;

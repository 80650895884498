import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

const usePrompt = (name: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const url = config.backend_url + "/prompt_templates/" + name;

  const fetchPrompt = async () => {
    if (!name) {
      return null;
    }
    const res = await fetchWithAuth(url).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useQuery({
    queryKey: ["prompt", name],
    queryFn: fetchPrompt,
    staleTime: 10000000000, // Infinite
  });
};

export default usePrompt;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import { Dataset } from "@/pages/datasets/particular-dataset";
import config from "@/config";

const useUpdatePromptDataset = (
  dataset_id: string,
  forceProdTo?: "qa" | "prod"
) => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["update_prompt_dataset", dataset_id];

  const updateFormData = async (formData: {
    commit_message: string;
    dataset: Dataset;
  }) => {
    let url = config.backend_url;
    if (forceProdTo === "prod") {
      url = config.production_backend_url;
    } else if (forceProdTo === "qa") {
      url = config.qa_backend_url;
    }
    url += "/prompt_dataset/" + dataset_id;

    let payload = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formData }),
    };
    const res = await fetchWithAuth(url, payload).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useMutation({
    mutationFn: updateFormData,
    onMutate: async (newPromptTemplate) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...newPromptTemplate,
      }));

      return { previousFormData };
    },

    onError: (err, newPromptTemplate, context: any) => {
      queryClient.setQueryData(cacheKey, context.previousFormData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default useUpdatePromptDataset;

import React from "react";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  handleChangePage: (pageNo: number) => void;
};

const CustomPagination = ({
  totalPages = 1,
  currentPage = 1,
  handleChangePage,
}: PaginationProps) => {
  const handleNext = () => {
    if (currentPage < totalPages) {
      handleChangePage(currentPage + 1);
    }
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      handleChangePage(currentPage - 1);
    }
  };

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem onClick={handlePrevious} className="cursor-pointer">
          <PaginationPrevious />
        </PaginationItem>

        {currentPage > 2 && (
          <>
            <PaginationItem
              onClick={() => handleChangePage(1)}
              className="cursor-pointer"
            >
              <PaginationLink isActive={currentPage === 1}>1</PaginationLink>
            </PaginationItem>
            {currentPage > 2 && <PaginationEllipsis />}
          </>
        )}

        {[...Array(3)].map((_, ind) => {
          const page_no = currentPage + ind - 1;
          if (page_no > totalPages || page_no < 1) return <></>;
          return (
            <PaginationItem
              onClick={() => handleChangePage(page_no)}
              className="cursor-pointer"
            >
              <PaginationLink isActive={currentPage === page_no}>
                {page_no}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        {currentPage < totalPages - 1 && (
          <>
            {currentPage < totalPages - 2 && <PaginationEllipsis />}
            <PaginationItem
              onClick={() => handleChangePage(totalPages)}
              className="cursor-pointer"
            >
              <PaginationLink isActive={currentPage === totalPages}>
                {totalPages}
              </PaginationLink>
            </PaginationItem>
          </>
        )}

        <PaginationItem onClick={handleNext} className="cursor-pointer">
          <PaginationNext />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default CustomPagination;

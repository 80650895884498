import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

let abortController: AbortController | null = null;
const useQueryLogs = ({ limit, offset }: { limit: number; offset: number }) => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["query_logs", limit.toString(), offset.toString()];

  const fetchQueryLogs = async (filters: any) => {
    let url = `${config.backend_url}/event_logs/event_groups/?limit=${limit}&offset=${offset}`;

    if (abortController) abortController.abort();

    abortController = new AbortController();

    let payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filters),
      signal: abortController.signal,
    };

    const res = await fetchWithAuth(url, payload).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useMutation({
    mutationFn: fetchQueryLogs,
    onMutate: async (newPromptTemplate) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...newPromptTemplate,
      }));

      return { previousFormData };
    },

    onError: (err, newPromptTemplate, context: any) => {
      queryClient.setQueryData(cacheKey, context.previousFormData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default useQueryLogs;

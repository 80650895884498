import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

import config from "@/config";

const usePromptVersionHistory = (name: string, isDraft: boolean = false) => {
  const fetchWithAuth = useFetchWithAuth();

  const url = `${config.backend_url}/prompt_templates/version_history/${name}?is_draft=${isDraft}`;

  const fetchPromptVersionHistory = async () => {
    if (!name) {
      return null;
    }
    const res = await fetchWithAuth(url).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useQuery({
    queryKey: ["prompt_version_history", name, isDraft],
    queryFn: fetchPromptVersionHistory,
  });
};

export default usePromptVersionHistory;

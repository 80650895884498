import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

interface PermissionManagerPayload {
  status: "approved" | "rejected";
  status_reason: string;
  ttl: number;
}

interface PostDataArgs {
  formData: PermissionManagerPayload;
  id: string;
}

const usePermissionManagepostAdmin = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["permission_manager"];

  const usepostData = async ({ formData, id }: PostDataArgs) => {
    const url = `${config.backend_url}/permission-manager/${id}`;
    const payload = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    const res = await fetchWithAuth(url, payload).then((res) => res.text());
    return res;
  };

  return useMutation({
    mutationFn: usepostData,
    onMutate: async (newFormData) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...newFormData,
      }));

      return { previousFormData };
    },

    onError: (
      err,
      newFormData,
      context: { previousFormData: unknown } | undefined
    ) => {
      if (context?.previousFormData) {
        queryClient.setQueryData(cacheKey, context.previousFormData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default usePermissionManagepostAdmin;

import React, { useState } from "react";
import WorkhackIcon from "@/assets/workhackLogo.svg";
import { Button } from "../ui/button";
import { supabase } from "@/supabaseClient";
import { useNavigate } from "react-router-dom";
import Loader from "../loader";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CURRENT_ENVIRONMENT } from "@/constants";
import { Environvent } from "@/types";
import useCurrentEnvironment from "@/actions/useCurrentEnvironment";

const environments = ["local", "development", "qa", "production"];

const Navbar = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleLogout = async () => {
    setLoading(true);
    await supabase.auth.signOut();
    setLoading(false);
    navigate("/", { replace: true });
  };

  const selectedEnvironment = useCurrentEnvironment();
  const handleselectedEnvironmentChange = (environment: Environvent) => {
    if (environment !== selectedEnvironment) {
      localStorage.setItem(CURRENT_ENVIRONMENT, environment);
      window.location.reload();
    }
  };

  return (
    <div className="h-14 border-b flex px-6 items-center justify-between">
      <img
        src={WorkhackIcon}
        alt="workhack-logo"
        className="w-12 cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="flex gap-5">
        <Select
          onValueChange={handleselectedEnvironmentChange}
          value={selectedEnvironment}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select Environment..." />
          </SelectTrigger>
          <SelectContent>
            {environments.map((env, ind) => (
              <SelectItem value={env} className="capitalize">
                {env}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button variant={"outline"} onClick={handleLogout}>
          Logout
        </Button>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Navbar;

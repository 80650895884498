import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

export type Feedback = {
  data: any;
};

const useMongoDPost = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["mongo-interface"];

  const updateFormData = async ({ data }: { data: Feedback }) => {
    console.log(data, "data data data");

    let url = config.backend_url + "/mongo-interface/update-document";
    let payload = {
      method: "POST", // Change the method to "DELETE" or any other appropriate method
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    };
    const res = await fetchWithAuth(url, payload)
      .then((res) => res.json())
      .catch((error) => {
        console.log(error, "error error error");
      });
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useMutation({
    mutationFn: updateFormData,
    onMutate: async (param) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...param,
      }));

      return { previousFormData };
    },

    onError: (err, param, context: any) => {
      queryClient.setQueryData(cacheKey, context.previousFormData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default useMongoDPost;

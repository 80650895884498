import React, { useState } from "react";
import Helmet from "react-helmet";
import WorkhackLogo from "@/assets/workhackLogo.svg";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/supabaseClient";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Turnstile } from "@marsidev/react-turnstile";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");

  const handleLogin = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
      options: { captchaToken },
    });
    if (error) {
      alert(error.message);
    } else {
      navigate("/", { replace: true });
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Workhack | Login</title>
      </Helmet>

      <div className="flex flex-col justify-center items-center h-dvh">
        <img src={WorkhackLogo} className="w-[80px]" alt="workhack-logo" />
        <form onSubmit={handleLogin} className="w-[300px] flex flex-col gap-4">
          <h6 className="text-xl">Login</h6>

          <div>
            <Label>Email</Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter workhack email here"
              disabled={loading}
            />
          </div>

          <div>
            <Label>Password</Label>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              type="password"
              disabled={loading}
            />
          </div>

          <div className="flex justify-center">
            <Turnstile
              siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY || ""}
              onSuccess={(token) => {
                setCaptchaToken(token);
              }}
            />
          </div>
          <Button className="w-full" type="submit" disabled={loading}>
            Login
          </Button>
        </form>
      </div>
    </>
  );
};

export default LoginPage;

import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

const useEventNames = () => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchEvents = async () => {
    const url = `${config.backend_url}/event_logs/event_names/`;
    const res = await fetchWithAuth(url).then((res) => res.json());

    if (res?.status === "error") {
      throw new Error(res?.description);
    }

    return res;
  };

  return useQuery({
    queryKey: ["event_names"],
    queryFn: fetchEvents,
  });
};

export default useEventNames;

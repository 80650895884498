import React, { useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { useParams } from "react-router-dom";

import PromptConfig, { PromptType } from "./prompt-config";
import VariableList, { VariableType } from "./variable-list";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import NewPromptDialog from "@/components/new-prompt-dialog";

import { Copy, Settings, SquarePlus, Trash } from "lucide-react";
import Versions from "@/components/versions";
import usePrompt from "@/actions/api/usePrompt";
import usePromptVersionHistory from "@/actions/api/usePromptVersionHistory";
import { usePromptVersion } from "@/actions/api/usePromptVersion";
import { cn } from "@/lib/utils";
import useSaveAsDraftPromptTemplate from "@/actions/api/useSaveAsDraftPromptTemplate";
import { toast } from "sonner";
import useUpdatePromptTemplate from "@/actions/api/useUpdatePromptTemplate";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useFieldArray, useForm } from "react-hook-form";

const Playground = ({
  state,
}: {
  state?: {
    prompt: PromptType[];
    variables: VariableType[];
  };
}) => {
  const { prompt_name } = useParams();
  const [newPromptDialogOpen, setNewPromptDialogOpen] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(-1);
  const [currentTab, setCurrentTab] = useState("prompt-config");
  const [draftName, setDraftName] = useState("");
  const [settingsOpen, setSettingsOpen] = useState(false);

  const updatepromptTemplate = useUpdatePromptTemplate(prompt_name || "");
  const updatepromptTemplateInProd = useUpdatePromptTemplate(
    prompt_name || "",
    "prod"
  );
  const updatepromptTemplateInQa = useUpdatePromptTemplate(
    prompt_name || "",
    "qa"
  );

  const { data: promptData, isLoading: promptDataLoading } = usePrompt(
    prompt_name || ""
  );

  const promptVersionHistory = usePromptVersionHistory(prompt_name || "");
  const { data: versionData } = promptVersionHistory;

  const promptDraftVersionHistory = usePromptVersionHistory(
    prompt_name || "",
    true
  );

  const { data: promptVersionData } = usePromptVersion(
    prompt_name || "",
    currentVersion,
    draftName
  );

  const { control, register, getValues, reset } = useForm<ModelParams>({
    defaultValues: {
      max_tokens: 0,
      model: "",
      stop_tokens: [],
      timeout_ms: 0,
    },
  });

  const {
    fields: stopTokens,
    append: addStopToken,
    remove: removeStopToken,
  } = useFieldArray({
    control,
    name: "stop_tokens",
  });

  const prompt = useMemo(() => {
    if (promptVersionData) return promptVersionData;
    if (promptData) {
      if (promptData.model_params) {
        reset({
          ...promptData.model_params,
          stop_tokens: promptData.model_params.stop_tokens.map(
            (stopToken: string) => ({ name: stopToken, id: Math.random() })
          ),
        });
      }
      return promptData;
    }

    // When redirecting from Logs Page
    if (state) {
      console.log("state", state);
      let prompt = state.prompt || [];

      state.variables.forEach((variable: VariableType) => {
        prompt.forEach((prompt: PromptType) => {
          if (prompt.content.includes(variable.value)) {
            const startIndex = prompt.content.indexOf(variable.value);
            prompt.content =
              prompt.content.slice(0, startIndex) +
              `{{ ${variable.key} }}` +
              prompt.content.slice(startIndex + variable.value.length);
          }
        });
      });
      return { prompt: { prompt } };
    }
    return [];
  }, [state, promptData, promptVersionData]);

  const variables = useMemo(() => {
    if (state?.variables) return state.variables;
    let variables: VariableType[] = [];
    let matches;
    const regex = /{{\s*(.*?)\s*}}/g;

    prompt?.prompt?.prompt?.forEach((message: any) => {
      while ((matches = regex.exec(message.content)) !== null) {
        if (matches[1] === "datetime") {
          variables.push({
            type: "date/time",
            key: matches[1],
            value: "",
          });
        } else {
          variables.push({
            type: "custom",
            key: matches[1],
            value: "",
          });
        }
      }
    });

    return variables;
  }, [state, prompt]);

  const handleSavePromptAsLatest = async ({
    commitMessage,
    chats,
    callback,
    forcePushTo,
  }: {
    commitMessage: string;
    chats: PromptType[];
    forcePushTo?: "prod" | "qa";
    callback?: () => void;
  }) => {
    if (!commitMessage) {
      toast.error("Commit message is required!!");
      return;
    }

    let actionFn = updatepromptTemplate;
    if (forcePushTo === "prod") {
      actionFn = updatepromptTemplateInProd;
    } else if (forcePushTo === "qa") {
      actionFn = updatepromptTemplateInQa;
    }

    const model_params: ModelParams = getValues();

    if (model_params.stop_tokens.length) {
      model_params.stop_tokens = model_params.stop_tokens.map((stopToken) => {
        return stopToken.name;
      }) as any;
    }

    await actionFn
      .mutateAsync({
        prompt: chats,
        commit_message: commitMessage,
        model_params,
      })
      .then(() => {
        toast.success("Prompt template updated successfully!");
        promptVersionHistory.refetch();
        // setSaveDialogOpen(false);
        callback?.();
      })
      .catch(() => {
        toast.error("Oops! Something went wrong. Please try again");
      });
  };

  const saveAsDraft = useSaveAsDraftPromptTemplate(prompt_name || "");
  const handleSaveAsDraft = async ({
    commitMessage,
    draftName,
    chats,
    callback,
  }: {
    commitMessage: string;
    draftName: string;
    chats: PromptType[];
    callback?: () => void;
  }) => {
    if (!commitMessage) {
      toast.error("Commit message is required!!");
      return;
    }
    await saveAsDraft
      .mutateAsync({
        prompt: chats,
        commit_message: commitMessage,
        draft_name: draftName,
      })
      .then(() => {
        toast.success("Prompt template saved as draft successfully!");
        promptVersionHistory.refetch();
        // setSaveDialogOpen(false);
        callback?.();
      })
      .catch(() => {
        toast.error("Oops! Something went wrong. Please try again");
      });
  };

  const handleNewPrompt = () => {
    setNewPromptDialogOpen(false);
  };

  const handleChangeVersion = (version: number, draftName: string) => {
    setCurrentVersion(version);
    setDraftName(draftName);
    setCurrentTab("prompt-config");
  };

  const handleCopyPromptName = () => {
    navigator.clipboard.writeText(prompt_name || "");
    toast.success("Prompt name copied to clipboard!");
  };

  const toggleSettingsOpen = () => {
    setSettingsOpen((prev) => !prev);
  };

  const handleAppendStopToken = (token: string) => {
    addStopToken({ name: token });
  };

  const handleDeleteStopToken = (index: number) => {
    removeStopToken(index);
  };

  return (
    <div
      className="p-5 grow flex flex-col"
      onClick={() => {
        if (settingsOpen) {
          setSettingsOpen(false);
        }
      }}
    >
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold">Playground</h1>
          {prompt_name && (
            <div className="flex items-center gap-2">
              <h3 className="text-base">Prompt: {prompt_name}</h3>
              <Copy
                size={16}
                className="cursor-pointer"
                onClick={handleCopyPromptName}
              />
            </div>
          )}
        </div>
        {!state && (
          <Dialog
            open={newPromptDialogOpen}
            onOpenChange={setNewPromptDialogOpen}
          >
            <DialogTrigger>
              <Button>
                <SquarePlus className="mr-2" /> New Prompt
              </Button>
            </DialogTrigger>
            <DialogContent>
              <NewPromptDialog onClick={handleNewPrompt} />
            </DialogContent>
          </Dialog>
        )}
      </div>

      <div className="grow flex mt-10 h-0 overflow-auto">
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            className="border-r px-2 flex flex-col gap-4"
            defaultSize={70}
          >
            <Tabs
              defaultValue="prompt-config"
              className="grow flex flex-col overflow-y-hidden"
              value={currentTab}
              onValueChange={setCurrentTab}
            >
              <div className="flex justify-between items-center">
                <TabsList className="w-fit">
                  <TabsTrigger value="prompt-config">Prompt</TabsTrigger>
                  {!!prompt_name && (
                    <>
                      <TabsTrigger value="version">Versions</TabsTrigger>
                      <TabsTrigger value="draft">Drafts</TabsTrigger>
                    </>
                  )}
                </TabsList>
                <p className="text-sm text-muted-foreground">
                  Version: {prompt?.version || 0}
                </p>
              </div>
              <TabsContent
                value="prompt-config"
                className="h-full overflow-y-hidden"
              >
                {!promptDataLoading && (
                  <div className="relative h-full ">
                    <PromptConfig
                      prompt={prompt?.prompt?.prompt || []}
                      handleSavePromptAsLatest={handleSavePromptAsLatest}
                      handleSaveAsDraft={handleSaveAsDraft}
                      showSaveBtn={!!prompt_name}
                    />

                    {prompt_name && (
                      <div
                        className="absolute z-50 bottom-0 left-0 "
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Settings
                          className={cn(
                            "cursor-pointer transition-transform duration-300",
                            settingsOpen && "rotate-45"
                          )}
                          onClick={toggleSettingsOpen}
                        />
                        <div
                          className={cn(
                            "bottom-8 border p-4 rounded-md bg-white w-80 space-y-2",
                            settingsOpen ? "absolute" : "hidden"
                          )}
                        >
                          <h3>Model Params</h3>
                          <div>
                            <Label className="text-sm text-gray-700">
                              Max Tokens
                            </Label>
                            <Input type="number" {...register("max_tokens")} />
                          </div>
                          <div>
                            <Label className="text-sm text-gray-700">
                              Model
                            </Label>
                            <Input
                              type="string"
                              placeholder="Model name"
                              {...register("model")}
                            />
                          </div>
                          <div>
                            <Label className="text-sm text-gray-700">
                              Stop tokens
                            </Label>
                            <Input
                              type="string"
                              placeholder="Stop tokens"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  handleAppendStopToken(e.currentTarget.value);
                                  e.currentTarget.value = "";
                                }
                              }}
                            />
                            <div className="flex gap-1 mt-2 flex-wrap">
                              {stopTokens.map((stopToken, index) => (
                                <Label
                                  key={index}
                                  className="text-sm text-gray-700 bg-gray-200 rounded-full px-2 py-1 flex items-center gap-1"
                                >
                                  {stopToken.name}
                                  <Trash
                                    className="cursor-pointer"
                                    onClick={() => handleDeleteStopToken(index)}
                                    size={16}
                                  />
                                </Label>
                              ))}
                            </div>
                          </div>
                          <div>
                            <Label className="text-sm text-gray-700">
                              Timeout (in ms)
                            </Label>
                            <Input type="number" {...register("timeout_ms")} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </TabsContent>

              <TabsContent
                value="version"
                className={cn(
                  "flex flex-col overflow-auto mt-0",
                  currentTab === "version" && "grow"
                )}
              >
                <Versions
                  versions={versionData || []}
                  handleChangeVersion={handleChangeVersion}
                />
              </TabsContent>

              <TabsContent
                value="draft"
                className={cn(
                  "flex flex-col overflow-auto mt-0",
                  currentTab === "draft" && "grow"
                )}
              >
                <Versions
                  isDraft={true}
                  versions={promptDraftVersionHistory.data || []}
                  handleChangeVersion={handleChangeVersion}
                />
              </TabsContent>
            </Tabs>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel className="px-2" defaultSize={30}>
            <VariableList variableList={variables} />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </div>
  );
};

export default Playground;

type ModelParams = {
  max_tokens: number;
  model: string;
  stop_tokens: {
    name: string;
  }[];
  timeout_ms: number;
};

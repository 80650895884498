import React from 'react';
import ReactSelect, { MultiValue } from 'react-select';
import { cn } from '@/lib/utils';

const EventSelector = ({
  onChange,
  value,
  options = [],
  className = '',
  isMulti = true
}: {
  onChange: (newValue: MultiValue<any>) => void;
  value: any;
  options: {
    label: string;
    value: string;
  }[];
  className?: string;
  isMulti?: boolean;
}) => {
  return (
    <ReactSelect
      isMulti={isMulti}
      menuShouldScrollIntoView
      options={options}
      onChange={onChange}
      value={value}
      className={cn('max-w-[400px] z-20', className)}
      styles={{
        valueContainer: style => ({
          ...style,
          display: 'flex',
          gap: '8px',
          flexWrap: 'nowrap'
        })
      }}
    />
  );
};

export default EventSelector;

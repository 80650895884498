import React from "react";
import { DialogHeader, DialogTitle } from "@/components/ui/dialog";
import Versions from "@/components/versions";
import { toast } from "sonner";
import useRestorePromptTemplate from "@/actions/api/useRestorePromptTemplate";
import usePromptVersionHistory from "@/actions/api/usePromptVersionHistory";

const RestoreDialog = ({
  promptName,
  refetchPrompts,
  setRestoreDialogOpen,
}: {
  promptName: string;
  refetchPrompts: () => void;
  setRestoreDialogOpen: React.Dispatch<number>;
}) => {
  const { data: promptVersions } = usePromptVersionHistory(promptName);
  const restorePromptTemplate = useRestorePromptTemplate(promptName);

  const handleRestoreVersion = async (version: number) => {
    if (version === promptVersions[0].version) {
      toast.error("Can not restore to latest version");
      return;
    }
    await restorePromptTemplate
      .mutateAsync({
        version: version,
      })
      .then(() => {
        toast.success("Prompt template restored successfully!");
        setRestoreDialogOpen(-1);
        refetchPrompts();
      })
      .catch(() => {
        toast.error("Oops! Something went wrong. Please try again");
      });
  };

  return (
    <div className="flex flex-col gap-2 max-h-[500px] overflow-auto">
      <DialogHeader>
        <DialogTitle>Restore to previous prompt</DialogTitle>
      </DialogHeader>

      <Versions
        versions={promptVersions}
        handleChangeVersion={handleRestoreVersion}
      />
    </div>
  );
};

export default RestoreDialog;

import { CURRENT_ENVIRONMENT } from "@/constants";
import { Environvent } from "@/types";

const useCurrentEnvironment = () => {
  const currentEnvironment = (localStorage.getItem(CURRENT_ENVIRONMENT) ??
    "development") as Environvent;

  return currentEnvironment;
};

export default useCurrentEnvironment;

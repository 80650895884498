import React from 'react';
import { Button } from '@/components/ui/button';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table
} from '@/components/ui/table';
import { Pencil, SquarePlus, Star, Trash2 } from 'lucide-react';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import DeleteDialog from './delete-dialog';
import CreateReleaseDialog from './release-data-dialog';

const ReleasePage = () => {
  const releases = [
    {
      timestamp: '',
      avg_rating: 4,
      name: 'Release name'
    }
  ];

  return (
    <div className='p-5 flex min-h-dvh flex-col'>
      <div className='flex justify-between items-center'>
        <h1 className='text-3xl font-semibold'>Releases</h1>
        <Dialog>
          <DialogTrigger>
            <Button>
              <SquarePlus className='mr-2' /> Release
            </Button>
          </DialogTrigger>
          <DialogContent>
            <CreateReleaseDialog title='Create new Release' />
          </DialogContent>
        </Dialog>
      </div>
      <div className='mt-10'>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className='w-[150px]'>Name</TableHead>
              <TableHead className='text-right'>Timestamp</TableHead>
              <TableHead className='text-right'>Average Rating</TableHead>
              <TableHead className='text-right'>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {releases.map((release: any, ind: number) => (
              <TableRow key={ind}>
                <TableCell className='font-medium cursor-pointer'>
                  {release.name}
                </TableCell>
                <TableCell className='text-right'>
                  2024-05-06 13:50:05.553411
                </TableCell>
                <TableCell className=''>
                  <div className='flex gap-2 items-center justify-end'>
                    <p className='text-md font-semibold'>
                      {release.avg_rating}
                    </p>
                    <Star />
                  </div>
                </TableCell>
                <TableCell className='flex justify-end gap-3'>
                  {/* Edit Dialog */}
                  <Dialog>
                    <DialogTrigger>
                      <Pencil className='cursor-pointer' />
                    </DialogTrigger>
                    <DialogContent>
                      <CreateReleaseDialog
                        title='Edit Release'
                        data={release}
                      />
                    </DialogContent>
                  </Dialog>

                  {/* Delete Release */}
                  <Dialog>
                    <DialogTrigger>
                      <Trash2 className='cursor-pointer' />
                    </DialogTrigger>
                    <DialogContent>
                      <DeleteDialog />
                    </DialogContent>
                  </Dialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ReleasePage;

import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import useRenamePromptTemplate from '@/actions/api/useRenamePromptTemplate';
import { toast } from 'sonner';

const RenameDialog = ({
  promptName,
  setRenameDialogOpen,
  refetchPrompts
}: {
  promptName: string;
  setRenameDialogOpen: React.Dispatch<number>;
  refetchPrompts: () => void;
}) => {
  const [newName, setNewName] = useState(promptName);
  const renamePromptTemplate = useRenamePromptTemplate(promptName);

  const handleRenamePrompt = async () => {
    await renamePromptTemplate
      .mutateAsync({
        name: newName
      })
      .then(() => {
        toast.success('Prompt template renamed successfully!');
        setRenameDialogOpen(-1);
        refetchPrompts();
      })
      .catch(e => {
        console.log(e);
        toast.error('Oops! Something went wrong. Please try again');
      });
  };

  return (
    <div className='flex flex-col gap-2'>
      <DialogHeader>
        <DialogTitle>Modify prompt name</DialogTitle>
      </DialogHeader>

      <div>
        <Label>Prompt Name</Label>
        <Input
          placeholder='Prompt Name'
          value={newName}
          onChange={e => setNewName(e.target.value)}
        />
      </div>

      <DialogFooter>
        <Button onClick={handleRenamePrompt}>Save</Button>
      </DialogFooter>
    </div>
  );
};

export default RenameDialog;

import React, { useMemo, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import moment from "moment";
import CustomPagination from "../pagination";

type Version = {
  timestamp: string;
  version: string;
  commit_by: string;
  commit_message: string;
  draft_name?: string;
};

const Versions = ({
  versions = [],
  handleChangeVersion,
  isDraft = false,
}: {
  versions: any[];
  handleChangeVersion?: (version: number, draftName: string) => void;
  isDraft?: boolean;
}) => {
  const ITEM_IN_ONE_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => {
    return Math.ceil(versions.length / ITEM_IN_ONE_PAGE);
  }, [versions.length]);

  return (
    <div className="grow flex flex-col">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Timestamp</TableHead>
            <TableHead>{isDraft ? "Draft name" : "Version"}</TableHead>
            <TableHead>Commit Message</TableHead>
            <TableHead className="text-right">Commit By</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {versions
            ?.slice(
              (currentPage - 1) * ITEM_IN_ONE_PAGE,
              currentPage * ITEM_IN_ONE_PAGE
            )
            ?.map((version: Version, ind: number) => (
              <TableRow
                key={ind}
                onClick={() =>
                  handleChangeVersion?.(
                    parseInt(version.version),
                    version?.draft_name || ""
                  )
                }
                className="cursor-pointer"
              >
                <TableCell>
                  {moment(version.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
                <TableCell>
                  {isDraft ? version.draft_name || "" : version.version}
                </TableCell>
                <TableCell>{version.commit_message}</TableCell>
                <TableCell className="text-right">
                  {version.commit_by}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {!versions?.length && (
          <p className="text-lg text-center my-4 text-muted-foreground w-full">
            No versions to display!!
          </p>
        )}
      </Table>

      <div  className="w-[70%]  ">
        <CustomPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handleChangePage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Versions;

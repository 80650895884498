import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { DataTable } from "../hipa/components/data-table";
import { Column, ColumnDef, Row } from "@tanstack/react-table";
import { DataTableColumnHeader } from "../hipa/components/data-table-column-header";
import CreateRequest from "../hipa/components/form/create-request";
import { useMemo } from "react";
import usePostRedShift from "@/actions/api/usePostRedshift";

export const formSchema = z.object({
  org_id: z.string().min(1, "Organization ID is required"),
  query: z.string().min(1, "Query is required"),
  table: z.string().min(1, "table is required"),
});

export type FormData = z.infer<typeof formSchema>;

function Redshift() {
  const { data, mutate } = usePostRedShift();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data: FormData) => {
    mutate({ data });
  };

  const columns = data?.length
    ? Object.keys(data?.[0]).map((key) => ({
        accessorKey: key,
      }))
    : [];

  return (
    <div>
      <div className="w-[90%] mx-auto pt-2">
        <Card>
          <form className="pl-5 py-4 " onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="mt-4">
                <Label htmlFor="query" className="mb-4">
                  Table
                </Label>
                <Input
                  id="table"
                  {...register("table")}
                  className="w-[500px] my-3  "
                />
                {errors.table && (
                  <p className="text-red-500">{errors.table.message}</p>
                )}
              </div>
              <Label htmlFor="org_id" className="mb-4">
                Organization ID
              </Label>
              <Input
                id="org_id"
                className="w-[500px] mt-3  "
                {...register("org_id")}
              />
              {errors.org_id && (
                <p className="text-red-500">{errors.org_id.message}</p>
              )}
            </div>
            <div className="mt-4">
              <Label htmlFor="query" className="mb-4">
                Query
              </Label>
              <Textarea
                id="query"
                {...register("query")}
                className="w-[90%] mt-3 "
              />
              {errors.query && (
                <p className="text-red-500">{errors.query.message}</p>
              )}
            </div>
            <Button type="submit" className="mt-4">
              Submit
            </Button>
          </form>
        </Card>

        <div className="mt-4">
          {data?.length && <DataTable data={data} columns={columns} />}
        </div>
      </div>
    </div>
  );
}

export default Redshift;

const Header = ({ column }: { column: Column<any, unknown> }) => {
  return <DataTableColumnHeader column={column} title={column.id} />;
};

const CellComponent = ({ row }: { row: Row<any> }) => {
  return <CreateRequest id={row.original._id} />;
};

const generateColumns = (data: any[]): ColumnDef<any>[] => {
  if (data.length === 0) return [];

  const columns: ColumnDef<any>[] = Object.keys(data[0]).map((key) => ({
    accessorKey: key,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={key} />
    ),
    cell: ({ row }) => <div>{row.original[key]}</div>,
  }));

  columns.push({
    id: "actions",
    header: ({ column }) => <Header column={column} />,
    cell: ({ row }) => <CellComponent row={row} />,
    enableSorting: false,
    enableHiding: false,
  });

  return columns;
};

export const useColumns = (data: any[]) => {
  const columns = useMemo(() => generateColumns(data), [data]);
  return columns;
};

import React, { useRef, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { toast } from 'sonner';
import { Prompt } from './particular-dataset';
import useAddNewPromptDataset from '@/actions/api/useAddNewPromptDataset';
import { useNavigate } from 'react-router-dom';

const AddDatasetButton = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [datasetName, setDatasetName] = useState('');
  const addNewpromptDataset = useAddNewPromptDataset();

  const handleUploadClick = () => {
    inputRef?.current?.click();
  };

  const onUploadDataset = (jsonData: string) => {
    const data: any[] = JSON.parse(jsonData);
    if (Array.isArray(data)) {
      const uploadedPrompts = data.map(prompt => ({
        name: prompt.name || 'New scenario',
        messages: prompt.messages
      }));
      handleAddNewDataset(uploadedPrompts);
    }
  };

  const handleInputFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const reader = new FileReader();
    reader.onload = e => onUploadDataset(e.target?.result as string);
    reader.readAsText(event.target.files?.[0] as any);
  };

  const handleAddNewDataset = async (prompts: Prompt[] = []) => {
    if (!datasetName) {
      toast.error('Please provide dataset name');
      return;
    }

    await addNewpromptDataset
      .mutateAsync({ name: datasetName, prompts })
      .then(res => {
        toast.success('Prompt dataset added successfully!');
        navigate(`/dataset/${res.dataset_id}`);
      })
      .catch(() => {
        toast.error('Oops! Something went wrong. Please try again');
      });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Add Dataset</Button>
      </DialogTrigger>
      <DialogContent>
        <div className='flex flex-col gap-5'>
          <DialogHeader>
            <DialogTitle>Add Dataset</DialogTitle>
          </DialogHeader>
          <div className=''>
            <Label>Name</Label>
            <Input
              placeholder='Name of dataset'
              value={datasetName}
              onChange={e => setDatasetName(e.target.value)}
            />
          </div>
          <div className='flex gap-5'>
            <Button
              variant='outline'
              className='basis-1/2'
              onClick={() => handleAddNewDataset()}
            >
              Add Empty Dataset
            </Button>

            <Button className='basis-1/2' onClick={handleUploadClick}>
              Upload a dataset
            </Button>
          </div>

          <Input
            ref={inputRef}
            type='file'
            className='hidden'
            accept='application/JSON'
            onChange={handleInputFileChange}
            multiple={false}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddDatasetButton;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "@/config";

const useAddNewPromptTemplate = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  const cacheKey = ["new_prompt_template"];

  const updateFormData = async (formData: { name: string }) => {
    let url = config.backend_url + "/prompt_templates/";

    let payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formData, status: "draft" }),
    };
    const res = await fetchWithAuth(url, payload).then((res) => res.json());
    if (res?.status === "error") {
      throw new Error(res?.description);
    }
    return res;
  };

  return useMutation({
    mutationFn: updateFormData,
    onMutate: async (newPromptTemplate) => {
      await queryClient.cancelQueries({ queryKey: cacheKey });
      const previousFormData = queryClient.getQueryData(cacheKey);

      queryClient.setQueryData(cacheKey, (old: any) => ({
        ...old,
        ...newPromptTemplate,
      }));

      return { previousFormData };
    },

    onError: (err, newPromptTemplate, context: any) => {
      queryClient.setQueryData(cacheKey, context.previousFormData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

export default useAddNewPromptTemplate;

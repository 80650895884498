import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  const pages = [
    "logs",
    "prompts",
    "playground",
    "release",
    "dataset",
    "permission",
    "editor",
    "redshift",
    "useracess",
  ];

  return (
    <div className="flex gap-5 p-5">
      {pages.map((page, ind) => (
        <Link to={page} key={ind} className=" border rounded p-10 capitalize">
          {page} page
        </Link>
      ))}
    </div>
  );
};

export default HomePage;
